import React from 'react';
import { Helmet } from 'react-helmet';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// style
import { Container, Content } from './style.js';

export default function Laboratorio() {
  return (
    <>
      <Helmet>
        <title>Laboratório</title>
        <meta
          name="description"
          content="Confiança, agilidade e eficiência são valores do Laboratório Santa Casa. A tecnologia é um grande destaque do Laboratório, e está presente desde a coleta até a consulta dos resultados pela internet."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-laboratorio-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content>
          <h4>
            Laboratório Santa Casa
            <img
              src={require('../../assets/img/lab-detail.svg')}
              alt="Detalhe"
            />
          </h4>
          <a
            href="http://200.146.34.139:8088/LaboratorioWeb/indexLaboratorio.jsp"
            target="_blank"
            rel="noopener noreferrer"
          >
            RESULTADOS DE EXAMES
          </a>
          <p>
            A tecnologia é um grande destaque do Laboratório, e está presente
            desde a coleta até a consulta dos resultados pela internet. Além
            disso, confiança, agilidade e eficiência são valores do Laboratório
            Santa Casa. Por isto utilizamos controle certificado e comprovado
            por programas de excelência como:
          </p>
          <div id="logos">
            <img
              src={require('../../assets/img/logo-controlab.png')}
              alt="Logo Controlab"
            />
            <img
              src={require('../../assets/img/logo-pelm.png')}
              alt="Logo pelm"
            />
            <img
              src={require('../../assets/img/logo-reblas.png')}
              alt="Logo reblas"
            />
          </div>
          <div id="exames">
            <a
              href={require('../../assets/pdf/exames.pdf')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                Confira <span>AQUI</span> os Exames que realizamos.
              </p>
            </a>
          </div>
          <div id="imagens">
            <img
              src={require('../../assets/img/lab-detail.svg')}
              alt="Detalhe"
              id="detail"
            />
            <div>
              <img
                src={require('../../assets/img/lab02.jpg')}
                alt="Laboratório"
              />
            </div>
            <div id="img2">
              <img
                src={require('../../assets/img/laboratorio.jpg')}
                alt="Laboratório"
              />
            </div>
          </div>
        </Content>
        <Footer />
      </Container>
    </>
  );
}
