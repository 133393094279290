import styled from 'styled-components';

export const Navbar = styled.header`
  height: 125px;
  position: fixed;
  width: 100%;
  z-index: 99;

  @media (max-width: 1380px) {
    height: 155px;
  }

  @media (max-width: 905px) {
    height: 115px;
  }

  @media (max-width: 450px) {
    height: 85px;
  }

  #content {
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
    width: 100%;
    height: 75px;
    padding: 0 20px;

    @media (max-width: 1420px) {
      height: 105px;
      justify-content: space-between;
    }

    @media (max-width: 905px) {
      height: 65px;
    }

    #logo {
      position: relative;
      display: flex;
      height: 155px;
      width: 164px;
      margin-right: 50px;

      @media (max-width: 1380px) {
        margin-right: 80px;
      }

      @media (max-width: 905px) {
        margin-right: 0px;
      }

      @media (max-width: 450px) {
        height: 115px;
        width: 120px;
      }

      a {
        padding: 40px 30px 30px 30px;
        background-color: #fff;
        position: relative;
        z-index: 3;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.2);

        @media (max-width: 450px) {
          padding: 30px 20px 20px 20px;
        }

        img {
          width: auto;

          @media (max-width: 450px) {
            width: 80px;
          }
        }
      }

      #detail {
        position: absolute;
        right: -50px;
        top: -125px;
        z-index: 2;

        @media (max-width: 450px) {
          width: 65px;
          top: -78px;
          right: -33px;
        }

        @media (max-width: 370px) {
          right: -15px;
        }
      }
    }

    nav {
      padding-top: 15px;

      @media (max-width: 905px) {
        position: absolute;
        right: ${(props) => (props.isMenuOpen ? '0px' : '-500px')};
        width: 45%;
        top: 75px;
        background-color: #fff;
        padding: 20px;
        box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        z-index: 1;
        transition: all ease 0.5s;
      }

      @media (max-width: 600px) {
        width: 55%;
      }

      @media (max-width: 450px) {
        width: 65%;
      }

      ul {
        display: flex;

        @media (max-width: 1420px) {
          flex-wrap: wrap;
        }

        @media (max-width: 905px) {
          flex-direction: column;
          align-items: center;
        }

        li {
          list-style: none;
          margin: 0 10px;
          max-width: 140px;
          display: flex;
          flex-direction: column;

          @media (max-width: 1420px) {
            margin: 5px 10px;
          }

          @media (max-width: 905px) {
            margin: 10px;
            max-width: 100%;
            text-align: center;
          }

          a {
            font-size: 14px;
            line-height: 17px;
            color: #282b81;
            text-decoration: none;
          }

          #detail {
            width: 0;
            height: 0;
            border: 8px solid;
            border-color: #282b81 #fff #fff #282b81;
            margin-top: 5px;
            display: none;
          }
        }

        li:nth-child(even) {
          a {
            color: #0094bf;
          }

          #detail {
            border-color: #0094bf #fff #fff #0094bf;
          }
        }

        li:nth-child(5) {
          max-width: 115px;

          @media (max-width: 905px) {
            max-width: 100%;
          }
        }

        li:hover {
          #detail {
            display: inline;
          }
        }
      }
    }
  }

  #bottom-detail {
    height: 50px;
    background-color: rgba(243, 232, 46, 0.8);
    border-left: 200px solid rgba(40, 43, 129, 0.8);

    @media (max-width: 450px) {
      border-left: 100px solid rgba(40, 43, 129, 0.8);
      height: 20px;
    }
  }
`;
