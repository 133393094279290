import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding-top: 125px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: 80px auto 50px auto;
    padding: 0 20px;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 600px) {
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    span {
      font-weight: bold;
    }
  }

  > div {
    max-width: 1050px;
    margin: auto;
    margin-bottom: 50px;
    padding: 0 20px;

    @media (max-width: 685px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-bottom: 80px;

      @media (max-width: 750px) {
        margin-bottom: 40px;
      }
    }

    p,
    strong {
      font-size: 16px;
      line-height: 30px;
      color: #282b81;
      text-align: center;
    }

    strong {
      font-weight: bold;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 33%);

      @media (max-width: 1010px) {
        grid-template-columns: repeat(2, 50%);
      }

      @media (max-width: 685px) {
        grid-template-columns: repeat(1, 325px);
      }

      @media (max-width: 325px) {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  button {
    padding: 10px 20px;
    background-color: #0094bf;
    border-radius: 12px;
    border: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: #fff;
    margin: auto;
    cursor: pointer;
    transition: all ease 0.5s;

    :hover {
      background-color: #282b81;
    }
  }

  #imagens {
    display: flex;
    margin-bottom: 90px;
    position: relative;
    top: 50px;

    #detail {
      position: absolute;
      bottom: -115px;
      right: 0;

      @media (max-width: 600px) {
        left: 0;
        transform: rotate(90deg);
      }
    }

    @media (max-width: 685px) {
      flex-direction: row;
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    > div {
      width: 260px;
      height: 260px;
      border-radius: 12px;
      position: relative;

      ::before {
        content: '';
        width: 260px;
        height: 260px;
        position: absolute;
        background-color: #0094bf;
        border-radius: 12px;
        top: 90px;
        right: 115px;
        z-index: -1;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    #img1 {
      margin-right: 40px;

      @media (max-width: 660px) {
        margin-right: 20px;
      }

      @media (max-width: 600px) {
        margin-right: 0px;
      }
    }

    #img2 {
      margin-left: 40px;

      @media (max-width: 660px) {
        margin-left: 20px;
      }

      @media (max-width: 600px) {
        margin-left: 0px;
        margin-top: 30px;
      }

      ::before {
        background-color: #f3e82e;

        @media (max-width: 600px) {
          right: -115px;
        }
      }
    }
  }

  #santacasa {
    width: 100%;
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
