import { createGlobalStyle } from 'styled-components';

import 'antd/es/notification/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/carousel/style/css';
import 'antd/es/collapse/style/css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: fit-content;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Lato', sans-serif;
  }

  .nodisplay {
    display: none;
  }

  .hamburger {
    padding: 15px 15px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media (max-width: 905px) {
      display: inline-block;
    }
}
    .hamburger:hover {
      opacity: 0.7; }
    .hamburger.is-active:hover {
      opacity: 0.7; }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
      background-color: #282b81; }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 40px;
      height: 4px;
      background-color: #282b81;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -10px; }
    .hamburger-inner::after {
      bottom: -10px; }

    /*
    * Collapse
    */
    .hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--collapse .hamburger-inner::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
    .hamburger--collapse .hamburger-inner::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--collapse.is-active .hamburger-inner::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
    .hamburger--collapse.is-active .hamburger-inner::before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

      .ant-modal {
        top: 30px;
      }

      .ant-modal-title {
        font-size: 30px;
        line-height: 42px;
        font-weight: bold;
        color: #282b81;
        text-align: center;

        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 36px;

        }
      }

      .ant-modal-content {
        background-color: transparent;
      }
      
      .ant-modal-body {
        padding: 0;

        .closeBtnOutubro {
          position: absolute;
          top: 10px;
          right: calc(25% + 10px);
          border: none;
          background-color: transparent;
          cursor: pointer;

          @media (max-width: 900px) {
            right: calc(10% + 10px);
          }

          @media (max-width: 550px) {
           right: calc(2.5% + 10px);
          }
          
          svg {
            height: 20px;
            width: auto;
          }
        }

        .outubro {
          object-fit: contain;
          width: 50%;
          margin-left: 25%;

          @media (max-width: 900px) {
            width: 80%;
            margin-left: 10%;
          }

          @media (max-width: 550px) {
            width: 95%;
            margin-left: 2.5%;
          }
        }

        #convenios {
          display: grid;
          grid-template-columns: repeat(2, 50%);
          position: relative;
          padding: 20px;
          background-color: #fff;
          

          @media (max-width: 560px) {
            grid-template-columns: 100%;
            width: 100%;
          }

          a {
            font-size: 16px;
            color: #282b81;
            line-height: 30px;
            margin: 0;

            :hover {
              color: #0094bf;
            }
            
            img {
              margin-right: 10px;
            }
          }
        }

        #consulte {
          padding: 20px;
          padding-top: 50px;
          background-color: #fff;


          p,
          a {
            margin-bottom: 0;
            font-size: 16px;
            color: #282b81;
            line-height: 22px;
          max-width: 240px;
          margin: auto;


          }

          a {
            font-weight: bold;
          }
        }

        .modal {
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fff;

          img {
            max-width: 100%;
          }


          @media (max-width: 750px) {
            padding: 20px;
          }

          h3 {
            font-size: 30px;
            line-height: 36px;
            color: #282b81;
            font-weight: bold;
            text-align: center;

            @media (max-width: 750px) {
              font-size: 24px;
              line-height: 30px;
            }
          }

          h4 {
            font-size: 24px;
            line-height: 30px;
            color: #0094bf;
            font-weight: bold;
            text-align: center;

            @media (max-width: 750px) {
              font-size: 20px;
              line-height: 26px;
            }
          }

          p {
            font-size: 16px;
            line-height: 30px;
            color: #282b81;
            margin-bottom: 10px;
          }

          #publicacao {
            color: #0094bf;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 30px;
              color: #282b81;
              margin-bottom: 0px;

              a {
                color: #0094bf;
                font-weight: bold;

                :hover {
                  color: #282b81;
                }
              }
            }
          }

          button {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border-radius: 12px;
            line-height: 20px;
            padding: 10px 20px;
            background-color: #0094bf;
            border: none;
            cursor: pointer;
            align-self: flex-end;
            transition: all ease 0.5s;

            :hover {
              background-color: #282b81;
            }
          }

          #tabela {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 30px auto;
            max-width: 595px;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .ant-collapse {
        display: flex;
        background-color: #fff;
        border: none;
        position: relative;

        > .ant-collapse-item {
          border: 1px solid #0094bf;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          padding: 0 20px;
          background-color: #f7f7f7;
          width: 260px;
          height: 260px;
          margin: 0 15px;
          display: flex;

          .ant-collapse-extra {
            button {
              font-size: 14px;
              line-height: 17px;
              color: #fff;
              font-weight: bold;
              background-color: #0094bf;
              border-radius: 12px;
              width: 125px;
              height: 35px;
              margin: auto;
              position: absolute;
              bottom: 20px;
              border: none;
              cursor: pointer;
              left: 46.5px;

              :hover {
                background-color: #282b81;
              }
            }
          }
        }  

        > .ant-collapse-item:last-child {
          border: 1px solid #0094bf;
          border-radius: 12px;
        }

         > .ant-collapse-item > .ant-collapse-header {
          font-size: 30px;
          line-height: 42px;
          font-weight: bold;
          color: #282b81;
          text-align: center;
          margin-top: 65px;
          padding: 0;
        }

        .ant-collapse-content {
          position: absolute;
          top: 260px;
          width: 870px;
          left: -15px;
          border: none;
        }
      }

      .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
        padding: 0%;
      }

      .ant-collapse-content-box {
        h4 {
          padding: 0;
          margin-bottom: 20px;
          border: none;
          text-align: start;
          padding-top: 80px;
          margin-top: 0;
        }

        p {
          font-size: 16px;
          line-height: 22px;
          color: #282b81;
          margin-bottom: 15px;
          margin-left: 0px;

          strong {
            color: #0094bf;
          }
        }

        ul {
          padding-bottom: 20px;
          margin-bottom: 0;

          li {
            font-size: 16px;
            line-height: 22px;
            color: #282b81;
            margin-bottom: 10px;
            margin-left: 30px;
   
          }
        }
      }
`;
