import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding-top: 125px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 775px) {
    padding-bottom: 140px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: 80px auto 85px auto;
    padding: 0 20px;
    position: relative;

    @media (max-width: 775px) {
      margin-bottom: 50px;
    }

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    span {
      font-weight: bold;
    }

    #detail {
      position: absolute;
      top: -180px;
      left: -200px;
    }
  }

  #content {
    display: flex;
    justify-content: space-between;
    width: 735px;

    @media (max-width: 775px) {
      flex-direction: column-reverse;
      padding: 0 20px;
      width: 100%;
    }

    #imagens {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      @media (max-width: 775px) {
        flex-direction: row;
        margin-top: 30px;
      }

      @media (max-width: 630px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      > div {
        width: 260px;
        height: 260px;
        border-radius: 12px;
        position: relative;

        ::before {
          content: '';
          width: 260px;
          height: 260px;
          position: absolute;
          background-color: #0094bf;
          border-radius: 12px;
          top: 90px;
          right: 115px;
          z-index: -1;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }

      #img2 {
        @media (max-width: 630px) {
          margin-top: 30px;
        }

        ::before {
          background-color: #f3e82e;

          @media (max-width: 630px) {
            right: -115px;
          }
        }
      }

      #detail {
        position: absolute;
        left: -115px;
        transform: rotate(180deg);
        top: 492px;

        @media (max-width: 775px) {
          display: none;
        }
      }
    }

    #text {
      max-width: 420px;

      @media (max-width: 775px) {
        max-width: unset;
        width: 100%;
      }
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: #282b81;

      strong {
        font-weight: bold;
      }
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
