import React from 'react';

// style
import { Info } from './style.js';

export default function Footer() {
  return (
    <Info>
      <div id="top-content">
        <div id="logo">
          <a href="/">
            <img src={require('../../assets/logo.svg')} alt="Logo" />
          </a>
        </div>
        <div id="informacoes">
          <div>
            <a id="tel" href="tel:+554230268000">
              (42) 3026-8000
            </a>
            <a href="mailto:sac@santacasapg.com">sac@santacasapg.com</a>
          </div>
          <p>Ouvidoria</p>
          <div>
            <a id="tel" href="tel:+554230268093">
              (42) 3026-8093
            </a>
            <a href="mailto:ouvidoria@santacasapg.com">
              ouvidoria@santacasapg.com
            </a>
          </div>
          <div id="icons">
            <a
              href="https://www.facebook.com/santacasapg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/img/facebook-icon.svg')}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZhVrjoLCHNk9mhQ-0mg7gg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/img/youtube-icon.svg')}
                alt="Youtube"
              />
            </a>
          </div>
          <div id="endereco">
            <div id="endereco-link">
              <a href="https://www.google.com.br/maps/place/Santa+Casa+de+Misericordia+-Ponta+Grossa/@-25.0891505,-50.1617429,17z/data=!3m1!4b1!4m2!3m1!1s0x94e81a3e30d25e43:0x91edf1be1ab9cde4?hl=pt-BR">
                <p>Av. Doutor Francisco Burzio, 774</p>
                <p>Centro - 84010-200</p>
                <p>Ponta Grossa - PR</p>
              </a>
            </div>
            <div>
              <p>Dr. Rogerio Santos Clemente</p>
              <p>Diretor Técnico Médico - CRM-PR: 12841</p>
              <p>Santa Casa de Misericórdia de Ponta Grossa, PR. © 2020.</p>
            </div>
          </div>
        </div>
      </div>
      <div id="bottom-content">
        <p>Todos os direitos reservados.</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://agenciaade.com.br/"
        >
          <p>
            Feito com{' '}
            <img
              src={require('../../assets/img/heart.svg')}
              alt="Coração ADE"
            />{' '}
            por agenciaade.com.br
          </p>
        </a>
      </div>
      <div id="border">
        <div />
        <div />
      </div>
    </Info>
  );
}
