import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { Modal } from 'antd';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// styles
import { Container, Content, Loader } from './style';

// api
import api from '../../services/api';

export default function Centrodeespecialidades() {
  const [dados, setDados] = useState();
  const [showModal, setShowmodal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [convenios, setConvenios] = useState();

  useEffect(() => {
    async function loadDados() {
      await api.get(`/pages?slug=centro-de-especialidades`).then((response) => {
        setDados(response.data[0]);
        setLoading(false);
      });
    }
    loadDados();

    async function loadConvenios() {
      await api.get(`/pages?slug=convenios`).then((response) => {
        setConvenios(response.data[0]);
        setLoading(false);
      });
    }
    loadConvenios();
  }, []);

  return (
    <>
      <Helmet>
        <title>Centro de Especialidades</title>
        <meta
          name="description"
          content="A maior e melhor estrutura hospitalar da região conta agora com atendimento particular e convênios autorizados. São ofertadas consultas com hora marcada em mais de 30 especialidades e mais de 70 profissionais"
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-contato-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content className={loading ? 'nodisplay' : ''}>
          <h4>Centro de Especialidades</h4>
          <div>{ReactHtmlParser(!!dados && dados.content.rendered)}</div>
          <button type="button" onClick={() => setShowmodal(true)}>
            CONVÊNIOS
          </button>
          <div id="imagens">
            <img
              src={require('../../assets/img/esp-detail.svg')}
              alt="Detalhe"
              id="detail"
            />
            <div id="img1">
              <img
                src={require('../../assets/img/especialidades.jpg')}
                alt="Centro de Especialidades"
              />
            </div>
            <div id="img2">
              <img
                src={require('../../assets/img/especialidades2.jpg')}
                alt="Centro de Especialidades"
              />
            </div>
          </div>
          <img
            id="santacasa"
            src={require('../../assets/img/centroespecialidades.png')}
            alt="Centro de Especialidades"
          />

          <Modal
            title="Convênios Santa Casa"
            visible={showModal}
            onCancel={() => setShowmodal(false)}
            footer={false}
          >
            <div id="convenios">
              {!!convenios &&
                convenios.acf.convenios.map((convenio) => (
                  <a
                    href={convenio.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="alignnone size-medium wp-image-138"
                      src="http://projetos.agenciaade.com.br/santacasa/wp-content/uploads/2020/05/baec6461b0d69dde1b861aefbe375d8a.svg"
                      alt=""
                      width="15"
                      height="15"
                    />
                    {convenio.nome}
                  </a>
                ))}
            </div>
            <div id="consulte">
              <p>
                Consulte quais são as coberturas do seu convênio pelo telefone:
                <a href="tel:+554230279899">(42) 3027-9899</a> ou clique
                <a href="/contato"> AQUI</a>
              </p>
            </div>
          </Modal>
        </Content>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
