import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding: 125px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: 80px auto 50px auto;
    padding: 0 20px;
    position: relative;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 600px) {
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    img {
      position: absolute;
      transform: rotate(90deg);
      top: -80px;
      right: -450px;
    }

    span {
      font-weight: bold;
    }
  }
  a {
    background-color: #0094bf;
    border-radius: 12px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    line-height: 17px;
    padding: 10px 40px;
    text-decoration: none;
    transition: all ease 0.5s;
    border: none;

    :hover {
      background-color: #282b81;
    }
  }

  p {
    font-size: 16px;
    line-height: 30px;
    color: #282b81;
    text-align: center;
    margin-top: 55px;
    max-width: 735px;
    margin-bottom: 50px;
    padding: 0 20px;
  }

  #logos {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      margin: 10px 20px;
    }
  }

  #exames {
    padding: 18px 20px 10px 20px;
    background-color: #0094bf;
    width: 100%;
    margin-top: 90px;
    display: flex;
    justify-content: center;

    a {
      padding: 0;
      width: fit-content;

      p {
        font-size: 30px;
        line-height: 36px;
        font-weight: bold;
        color: #f3e82e;
        max-width: 370px;

        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 36px;
        }

        span {
          color: #fff;
        }
      }

      :hover {
        background-color: #0094bf;
      }
    }
  }

  #imagens {
    display: flex;
    margin-top: 40px;
    margin-bottom: 90px;
    position: relative;

    #detail {
      position: absolute;
      bottom: -130px;
      left: -280px;
    }

    @media (max-width: 590px) {
      flex-direction: column;
    }

    > div {
      width: 260px;
      height: 260px;
      border-radius: 12px;
      position: relative;

      ::before {
        content: '';
        width: 260px;
        height: 260px;
        position: absolute;
        background-color: #282b81;
        border-radius: 12px;
        top: 90px;
        right: 115px;
        z-index: -1;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    #img2 {
      margin-top: 90px;
      margin-left: 30px;

      @media (max-width: 590px) {
        margin-top: 30px;
      }

      ::before {
        background-color: #0094bf;

        @media (max-width: 590px) {
          right: -115px;
        }
      }
    }
  }
`;
