import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding: 125px 20px;
  scroll-behavior: smooth;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: auto;
    margin-top: 80px;
    padding-bottom: 65px;
    max-width: 900px;
    border-bottom: 1px solid #282b81;
    position: relative;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 500px) {
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    img {
      position: absolute;
      transform: rotate(90deg);
      top: -80px;
      right: -80px;

      @media (max-width: 500px) {
        display: none;
      }
    }

    span {
      font-weight: bold;
    }
  }

  > div {
    max-width: 900px;
    margin: auto;
    padding-bottom: 25px;
    border-bottom: 1px solid #282b81;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h3 {
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
      color: #282b81;
      text-align: center;
      margin: 30px auto;

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 26px;
      }

      span {
        font-weight: bold;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #282b81;
      margin: 10px 0;
      font-size: 16px;
      line-height: 30px;

      @media (max-width: 750px) {
        font-size: 14px;
        line-height: 28px;
      }

      :hover {
        color: #0094bf;
      }

      img {
        width: 50px;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  #informacoes {
    padding-top: 50px;
    padding-bottom: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      position: absolute;
      top: 0px;
      left: -200px;
    }

    #cards {
      display: grid;
      grid-template-columns: repeat(3, 260px);
      grid-auto-rows: 260px;
      grid-gap: 30px;
      padding-bottom: 0;
      border: 0;
      z-index: 5;

      @media (max-width: 880px) {
        grid-template-columns: repeat(2, 260px);
      }

      @media (max-width: 750px) {
        grid-template-columns: repeat(3, 200px);
        grid-auto-rows: 200px;
        grid-gap: 20px;
      }

      @media (max-width: 670px) {
        grid-template-columns: repeat(2, 200px);
      }

      @media (max-width: 460px) {
        grid-template-columns: repeat(1, 200px);
      }

      > div {
        padding: 0;
        border: 1px solid #0094bf;
        border-radius: 12px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 20px;
        background-color: #f7f7f7;

        h4 {
          border: none;
          margin-top: 65px;
          padding-bottom: 0;

          @media (max-width: 750px) {
            margin-top: 35px;
          }
        }

        a {
          font-size: 14px;
          line-height: 17px;
          color: #fff;
          font-weight: bold;
          background-color: #0094bf;
          border-radius: 12px;
          width: 125px;
          height: 35px;
          margin: auto;
          position: absolute;
          bottom: 20px;
          cursor: pointer;
          border: none;
          transition: all ease 0.5s;

          :hover {
            background-color: #282b81;
          }
        }
      }
    }

    #conteudo {
      width: 100%;
      padding-top: 150px;
      margin-top: -150px;

      @media (max-width: 450px) {
        padding-top: 150px;
        margin-top: -150px;
      }

      h4 {
        padding: 0;
        margin-bottom: 20px;
        border: none;
        text-align: start;
        padding-top: 80px;
        margin-top: 0;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: #282b81;
        margin-bottom: 15px;
        margin-left: 0px;

        strong {
          color: #0094bf;
        }
      }

      ul {
        margin-bottom: 0;

        li {
          font-size: 16px;
          line-height: 22px;
          color: #282b81;
          margin-bottom: 10px;
          margin-left: 30px;
        }
      }
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
