import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// style
import { Container, Content, Loader } from './style';

// api
import api from '../../services/api';

export default function Institucional() {
  const [dados, setDados] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDados() {
      await api.get(`/pages?slug=institucional`).then((response) => {
        setDados(response.data[0]);
        setLoading(false);
      });
    }
    loadDados();
  }, []);

  return (
    <>
      <Helmet>
        <title>Institucional</title>
        <meta
          name="description"
          content="Em 1º. de julho de 1907, ou seja, há mais de um século, a caridade e o desejo de bem servir a comunidade dos Campos Gerais motivaram distintos cavalheiros e damas abnegadas da sociedade pontagrossense a lançar a pedra fundamental para a construção da Santa Casa de Misericórdia de Ponta Grossa"
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-institucional-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content className={loading ? 'nodisplay' : ''}>
          <h4>
            Conheça a <br /> <span>Santa Casa de Ponta Grossa</span>
          </h4>
          <div id="institucionalvideo">
            <div id="videodiv">
              <audio>
                <track kind="captions" />
              </audio>
              <iframe
                title="video"
                width="720px"
                height="400px"
                src={!!dados && dados.acf.video}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <img
                id="detail1"
                src={require('../../assets/img/video-detail3.svg')}
                alt="Detalhe"
              />
              <img
                id="detail2"
                src={require('../../assets/img/video-detail2.svg')}
                alt="Detalhe"
              />
              <img
                id="detail3"
                src={require('../../assets/img/video-detail1.svg')}
                alt="Detalhe"
              />
            </div>
          </div>
          <div id="texto">
            <h4>
              <span>História</span>
            </h4>
            {ReactHtmlParser(!!dados && dados.content.rendered)}
            <div style={{maxWidth: '800px', margin: '20px auto', padding: '30px 0', textAlign: 'center', borderTop: '1px solid rgb(40, 43, 129)', borderBottom: '1px solid rgb(40, 43, 129)'}}>
              <h4>
                <span>Política de Privacidade e Proteção de Dados</span>
              </h4>
              <a 
                target="_blank"
                rel="noopener noreferrer"
                href="https://santacasapg.com/politica_privacidade.pdf"
                style={{color: '#282b81'}}
              >
                <img
                  id="santacasaimg"
                  src={require('../../assets/img/icons-security-shield-100.png')}
                  alt="Santa Casa"
                /><br/>
                <span style={{display: 'block', fontSize: '1.2em', fontWeight: 'bold', marginTop: '10px'}}>Clique para mais detalhes</span>
              </a>
            </div>
          </div>
          
          <div id="bottom-img">
            <img
              id="santacasaimg"
              src={require('../../assets/img/fachada-santacasa.jpg')}
              alt="Santa Casa"
            />
            <img
              id="detail"
              src={require('../../assets/img/fachada-detail.svg')}
              alt="Santa Casa"
            />
          </div>
        </Content>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
