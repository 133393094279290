import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// api
import api from '../../services/api';

// style
import { Container, Content, Loader } from './style';

export default function Outrosservicos() {
  const [dados, setDados] = useState();
  const [convenios, setConvenios] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDados() {
      await api.get(`/pages?slug=outros-servicos`).then((response) => {
        setDados(response.data[0]);
        setLoading(false);
      });
    }
    loadDados();

    async function loadConvenios() {
      await api.get(`/pages?slug=convenios`).then((response) => {
        setConvenios(response.data[0]);
        setLoading(false);
      });
    }
    loadConvenios();
  }, []);

  function playvideo() {
    document.getElementById('institucionalvd').load();
    document.getElementById('playbtn').classList.add('remove');
    setTimeout(function () {
      document.getElementById('institucionalvd').play();
      document.getElementById('playbtn').classList.add('nodisplay');
    }, 1000);
  }

  return (
    <>
      <Helmet>
        <title>Serviços Santa Casa</title>
        <meta
          name="description"
          content="Pediatria 24h, Oncologia, Hemodiálise, Centro Cirúrgico, Maternidade UTI Neonatal, Unidade de Terapia Intensiva Adulto – UTI e Hotelaria"
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-outros_servicos-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content className={loading ? 'nodisplay' : ''}>
          <h4>Outros Serviços</h4>

          {!!dados &&
            dados.acf.servicos.map((servico) => {
              if (dados.acf.servicos.indexOf(servico) === 0) {
                return (
                  <div
                    id="rowfirst"
                    key={servico.id}
                    style={{ backgroundColor: '#fff' }}
                  >
                    <div id="pediatriavideo">
                      <div id="videodiv">
                        <audio>
                          <track kind="captions" />
                        </audio>
                        <video
                          width="560px"
                          height="315px"
                          controls
                          id="institucionalvd"
                        >
                          <track kind="captions" />
                          <source
                            src={require('../../assets/Pediatria.mp4')}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div id="playbtn">
                        <label htmlFor="play">
                          <img
                            src={require('../../assets/img/play-icon.svg')}
                            alt="Play"
                          />
                        </label>
                        <img
                          id="bg"
                          src={require('../../assets/img/imgpediatria.jpg')}
                          alt="Video Capa"
                        />
                        <input
                          id="play"
                          type="button"
                          onClick={playvideo}
                        ></input>
                      </div>
                    </div>
                    <div id="text" style={{ maxWidth: '740px' }}>
                      {ReactHtmlParser(servico.descricao)}
                    </div>
                  </div>
                );
              }
              if (dados.acf.servicos.indexOf(servico) % 2 === 0) {
                return (
                  <div
                    id="row"
                    key={servico.id}
                    style={{ backgroundColor: '#fff' }}
                  >
                    <div id="imagem">
                      <img src={servico.imagem.sizes.thumbnail} alt="Icon" />
                    </div>
                    <div id="text">{ReactHtmlParser(servico.descricao)}</div>
                  </div>
                );
              }
              if (dados.acf.servicos.indexOf(servico) % 3 === 0) {
                return (
                  <div
                    id="rowpar"
                    key={servico.id}
                    style={{ backgroundColor: '#F2F2F2' }}
                  >
                    <div id="text">{ReactHtmlParser(servico.descricao)}</div>
                    <div id="imagem">
                      <img src={servico.imagem.sizes.thumbnail} alt="Icon" />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    id="rowpar"
                    key={servico.id}
                    style={{ backgroundColor: '#F3E82E' }}
                  >
                    <div id="text">{ReactHtmlParser(servico.descricao)}</div>
                    <div id="imagem">
                      <img src={servico.imagem.sizes.thumbnail} alt="Icon" />
                    </div>
                  </div>
                );
              }
            })}
          <div id="convenio">
            <h4>Convênios Santa Casa</h4>
            <div id="convenios">
              {!!convenios &&
                convenios.acf.convenios.map((convenio) => (
                  <a
                    href={convenio.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="alignnone size-medium wp-image-138"
                      src="http://projetos.agenciaade.com.br/santacasa/wp-content/uploads/2020/05/baec6461b0d69dde1b861aefbe375d8a.svg"
                      alt=""
                      width="15"
                      height="15"
                    />
                    {convenio.nome}
                  </a>
                ))}
              <div id="consulte">
                <p>
                  Consulte quais são as coberturas do seu convênio pelo
                  telefone:
                  <a href="tel:+554230279899">(42) 3027-9899</a> ou clique
                  <a href="/contato"> AQUI</a>
                </p>
              </div>
              <img
                id="detail"
                src={require('../../assets/logo-detail.svg')}
                alt="Detalhe"
              />
            </div>
            <div id="consulte2">
              <p>
                Consulte quais são as coberturas do seu convênio pelo telefone:
                <a href="tel:+554230279899">(42) 3027-9899</a> ou clique
                <a href="/contato"> AQUI</a>
              </p>
            </div>
          </div>
        </Content>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
