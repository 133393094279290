import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Info = styled.div`
  background-color: #f4f4f4;
  padding-top: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 825px) {
    padding-top: 80px;
  }

  @media (max-width: 635px) {
    padding-top: 50px;
  }

  @media (max-width: 340px) {
    padding-top: 30px;
  }

  #top-content {
    display: flex;
    padding: 0 20px;

    @media (max-width: 635px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    #logo {
      padding: 20px 40px 20px 0px;
      border-right: 1px solid #2d2e92;

      @media (max-width: 910px) {
        padding: 20px 20px 20px 0px;
      }

      @media (max-width: 635px) {
        border-bottom: 1px solid #2d2e92;
        border-right: none;
        padding: 20px 0;
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        img {
          width: 145px;
          height: auto;
        }
      }
    }

    #informacoes {
      margin-left: 40px;

      @media (max-width: 910px) {
        margin-left: 15px;
      }

      @media (max-width: 635px) {
        margin-left: 0px;
        margin-top: 20px;
        width: 100%;
      }

      > div {
        display: flex;
      }

      #icons {
        margin-left: 10px;
        margin-top: 5px;

        a {
          margin: 0 5px;

          img {
            width: 20px;
            height: auto;
          }
        }
      }

      #tel {
        font-weight: bold;

        @media (max-width: 560px) {
          margin-right: 20px;
        }
      }
    }

    p,
    a {
      font-size: 14px;
      text-decoration: none;
      color: #2d2e92;
      margin: 0 15px;

      @media (max-width: 635px) {
        margin: 0 10px;
      }

      @media (max-width: 560px) {
        margin: 0;
      }
    }

    #endereco {
      margin-top: 20px;

      @media (max-width: 750px) {
        flex-direction: column;
      }

      @media (max-width: 635px) {
        flex-direction: row;
      }

      @media (max-width: 560px) {
        flex-direction: column;
      }
    }

    #endereco-link {
      @media (max-width: 750px) {
        margin-bottom: 10px;
      }

      a {
        margin: 0;
      }
    }
  }

  #bottom-content {
    display: flex;
    padding-top: 30px;
    padding-bottom: 20px;

    @media (max-width: 470px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p,
    a {
      font-size: 14px;
      text-decoration: none;
      color: #2d2e92;
      margin: 0 15px;

      @media (max-width: 635px) {
        margin: 0 10px;
      }

      @media (max-width: 470px) {
        margin: 0;
      }
    }

    a {
      @media (max-width: 470px) {
        margin-top: 10px;
      }

      img {
        margin: 0 3px;
        height: 15px;

        animation-name: ${heartBeat};
        animation-iteration-count: infinite;
        animation-duration: 2s;
      }
    }
  }
  #border {
    display: flex;
    width: 100%;
    height: 15px;

    div {
      width: 55%;
      height: 15px;
      background-color: #0094bf;
    }

    div:nth-child(2) {
      background-color: #2d2e92;
      width: 45%;
    }
  }
`;
