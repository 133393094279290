/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import * as Yup from 'yup';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// style
import {
  Container,
  Banner,
  Links,
  Planos,
  Conheca,
  Beneficiarios,
  Inclusao,
  Manual,
  Direitos,
  Internacoes,
  Exclusao,
  Perguntas,
  Anexos,
  Loader,
} from './style';

// api
import ademail from '../../services/ademail';
import api from '../../services/api';

export default function Sas() {
  const [dados, setDados] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDados() {
      await api.get(`/pages?slug=sas`).then((response) => {
        setDados(response.data);
        setLoading(false);
      });
    }
    loadDados();
    if (dados) {
      setData(dados[0].acf);
    }
  }, [dados]);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <p>Nome: ${values.nome}</p>
            <p>E-mail: ${values.email}</p>
            <p>Telefone: ${values.telefone}</p>
            <p>Mensagem: ${values.mensagem}</p>
          `;

      const mail = {
        to: 'auditoria.sas@santacasapg.com',
        from: values.email,
        as: values.nome,
        // bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Nova ouvidoria via site - Santa Casa',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>SAS Santa Casa</title>
        <meta
          name="description"
          content="O Sistema de Assistência à Saúde (SAS) é um benefício concedido pelo Governo do Estado, ao servidor público, bem como para seus dependentes e pensionistas."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-plano-de-saude-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Banner className={loading ? 'nodisplay' : ''}>
          <img
            src={require('../../assets/img/bannersas.png')}
            alt="HÁ 108 ANOS A SAÚDE DOS CAMPOS GERAIS TEM SIDO
                      O NOSSO MAIOR PRESENTE."
          />
          <div id="banner-mobile">
            <h2>
              HÁ 108 ANOS A SAÚDE DOS CAMPO GERAIS TEM SIDO{' '}
              <strong>O NOSSO MAIOR PRESENTE.</strong>
            </h2>
          </div>
        </Banner>
        <Links className={loading ? 'nodisplay' : ''}>
          <div>
            <div>
              <img
                id="detail"
                src={require('../../assets/img/links-detail.svg')}
                alt="Detalhe"
              />
              <a href="#manual-do-beneficiario">
                <img
                  src={require('../../assets/img/guiamedico.svg')}
                  alt="Manual do Beneficiário"
                />
                <p>Manual do Beneficiário</p>
              </a>
              <a href="#informacoes-consultas">
                <img
                  src={require('../../assets/img/informações-sobre-consultas.png')}
                  alt="Informações sobre consultas"
                />
                <p>Informações sobre consultas</p>
              </a>
              <a href="#sas">
                <img
                  src={require('../../assets/img/sas.png')}
                  alt="Sistema de Assistência à Saúde"
                />
                <p>Sistema de Assistência à Saúde</p>
              </a>
              <a href="#internacoes">
                <img
                  src={require('../../assets/img/cama-de-hospital-icon.svg')}
                  alt="Internações"
                />
                <p>Internações</p>
              </a>
              <a href="#cobertura-assistencial">
                <img
                  src={require('../../assets/img/healthcare-icon.svg')}
                  alt="Cobertura Assistencial"
                />
                <p>Cobertura Assistencial</p>
              </a>
              <a href="#perguntas-frequentes">
                <img
                  src={require('../../assets/img/atendimento.svg')}
                  alt="Perguntas Frequentes"
                />
                <p>Perguntas Frequentes</p>
              </a>
              <a href="#inclusao-de-dependentes">
                <img src={require('../../assets/img/family-icon.png')} alt="" />
                <p>Inclusão de Dependentes</p>
              </a>
              <a href="#rede-credenciada">
                <img
                  src={require('../../assets/img/rede-credenciada.png')}
                  alt="Rede Credenciada"
                />
                <p>Rede Credenciada</p>
              </a>
              <div className="bluebox-background" />
              <img
                id="detail-2"
                src={require('../../assets/img/links-detail.svg')}
                alt="Detalhe"
              />
            </div>
          </div>
        </Links>

        <Planos className={loading ? 'nodisplay' : ''}>
          <div className="sas" id="sas">
            <h4>SAS</h4>
            {!!data && ReactHtmlParser(data.descricao_sas)}
          </div>
        </Planos>

        <Conheca
          className={loading ? 'nodisplay' : ''}
          id="informacoes-consultas"
        >
          <div id="nossosplanos">
            <div id="planos">
              <div id="rowpar">
                <div className="detail-image">
                  <img
                    src={require('../../assets/img/nossosplanos-detail.svg')}
                    alt="Detalhe"
                    id="detailnossosplanos"
                  />
                </div>
                <div id="card">
                  <h4>Agendamentos de Consultas</h4>
                  <img
                    src={require('../../assets/img/sas-agendamento.svg')}
                    alt="Icon"
                  />
                </div>
                <div id="textpar" name="text">
                  {!!data &&
                    ReactHtmlParser(
                      data.segunda_secao.agendamento_de_consultas.descricao
                    )}
                </div>
              </div>

              <div id="row">
                <div id="text" name="text">
                  {!!data &&
                    ReactHtmlParser(
                      data.segunda_secao.especialidades_secundarias.descricao
                    )}
                </div>
                <div id="card">
                  <h4>Especialidades Secundárias</h4>
                  <img
                    src={require('../../assets/img/sas-especialidades.svg')}
                    alt="Icon"
                  />
                </div>
              </div>

              <div id="rowpar">
                <div id="card">
                  <h4>Atrasos na consulta e Ausências nas Consultas</h4>
                  <img
                    src={require('../../assets/img/sas-atrasos.svg')}
                    alt="Icon"
                  />
                </div>
                <div id="textpar" name="text">
                  {!!data &&
                    ReactHtmlParser(
                      data.segunda_secao
                        .atrasos_na_consulta_e_ausencias_nas_consultas.descricao
                    )}
                </div>
              </div>
            </div>
          </div>
        </Conheca>

        <Beneficiarios
          className={loading ? 'nodisplay' : ''}
          id="rede-credenciada"
        >
          <div className="beneficiarios-container">
            <div className="line-top">
              <img
                src={require('../../assets/img/detail-beneficiario.svg')}
                alt="Detalhe"
              />
            </div>

            <div className="beneficiarios-text">
              <h2>Beneficiários</h2>
              <p>
                São considerados beneficiários do Sistema de Assistência à Saúde
                (SAS):
              </p>

              <div id="rowpar">
                <div id="card">
                  <h4>Na Condição de Titular</h4>
                  <img
                    src={require('../../assets/img/sas-condicao.svg')}
                    alt="Icon"
                  />
                </div>
                <div id="textpar" name="text">
                  {!!data &&
                    ReactHtmlParser(
                      data.beneficiarios.na_condicao_de_titular.descricao
                    )}
                </div>
              </div>

              <div id="row">
                <div id="text" name="text">
                  {!!data &&
                    ReactHtmlParser(
                      data.beneficiarios.na_condicao_de_dependente.descricao
                    )}
                </div>
                <div id="card">
                  <h4>Na condição de Dependente</h4>
                  <img
                    src={require('../../assets/img/sas-dependente.svg')}
                    alt="Icon"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="lei-container">
            <div className="lei-text">
              {!!data &&
                ReactHtmlParser(
                  data.beneficiarios.observacoes_dos_beneficiarios
                )}
            </div>
          </div>
        </Beneficiarios>

        <Inclusao
          className={loading ? 'nodisplay' : ''}
          id="inclusao-de-dependentes"
        >
          <div className="text">
            <img
              src={require('../../assets/img/detail-inclusao.svg')}
              alt="Detalhe"
            />
            <h2>Inclusão de Dependentes</h2>
            {!!data && ReactHtmlParser(data.inclusao_de_dependentes)}
          </div>
        </Inclusao>

        <Manual
          className={loading ? 'nodisplay' : ''}
          id="manual-do-beneficiario"
        >
          <div id="card">
            <h4>Manual do Beneficiário</h4>
            {!!data && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data.manual_do_beneficiario.link_do_botao_}
              >
                Acesse o link
              </a>
            )}
            <img src={require('../../assets/img/sas-manual.svg')} alt="Icon" />
          </div>
        </Manual>

        <Direitos
          className={loading ? 'nodisplay' : ''}
          id="cobertura-assistencial"
        >
          <p>
            Os beneficiários do SAS, devidamente incluídos e identificados no
            momento da prestação <br /> do atendimento, terão direito:
          </p>

          <div>
            <h3>Assistencia ambulatorial</h3>
            {!!data &&
              ReactHtmlParser(
                data.direitos_dos_beneficiarios.assistencia_ambulatorial
              )}
          </div>

          <div className="atendimento">
            <h3>Atendimento ambulatorial</h3>
            <p>
              O atendimento ambulatorial compreende consultas eletivas nas
              especialidades:
            </p>
            {!!data &&
              ReactHtmlParser(
                data.direitos_dos_beneficiarios.atendimento_ambulatorial
              )}
          </div>

          <div>
            <h3>Serviços e Terapias</h3>
            {!!data &&
              ReactHtmlParser(
                data.direitos_dos_beneficiarios.servicos_e_terapias
              )}
          </div>

          <div>
            <h3>Assistência Hospitalar</h3>
            {!!data &&
              ReactHtmlParser(
                data.direitos_dos_beneficiarios.assistencia_hospitalar
              )}
          </div>
        </Direitos>

        <Internacoes className={loading ? 'nodisplay' : ''} id="internacoes">
          <div className="text">
            <div className="image-container">
              <img
                src={require('../../assets/img/detail-expansao.svg')}
                alt="Detalhe"
              />
            </div>
            <h4>Internações</h4>
            {!!data &&
              ReactHtmlParser(data.direitos_dos_beneficiarios.internacoes)}
          </div>
        </Internacoes>

        <Exclusao className={loading ? 'nodisplay' : ''}>
          <div id="card">
            <h4>Descrição das Exclusões</h4>
            <img
              src={require('../../assets/img/sas-exclusoes.svg')}
              alt="Icon"
            />
            <img src={require('../../assets/img/detail-down.svg')} alt="Icon" />
          </div>
          <div className="exclusao-text">
            {!!data && ReactHtmlParser(data.descricao_das_exclusoes.descricao)}
          </div>
        </Exclusao>

        <Perguntas
          className={loading ? 'nodisplay' : ''}
          id="perguntas-frequentes"
        >
          <div id="card">
            <h4>Perguntas frequentes</h4>
            {!!data && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data.perguntas_frequentes.link_do_botao_}
              >
                Acesse o link
              </a>
            )}
            <img src={require('../../assets/img/sas-faq.svg')} alt="Icon" />
          </div>
        </Perguntas>

        <Anexos className={loading ? 'nodisplay' : ''}>
          <div>
            <h4>Fale com a Ouvidoria</h4>
            <form onSubmit={formik.handleSubmit}>
              <div id="inputs">
                <div>
                  <input
                    type="text"
                    name="nome"
                    placeholder="* NOME COMPLETO"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div>
                  <Input
                    type="tel"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    placeholder="* TELEFONE"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="* E-MAIL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>
              </div>
              <textarea
                name="mensagem"
                cols="30"
                rows="10"
                placeholder="MENSAGEM"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
              <button type="submit">ENVIAR</button>
            </form>
          </div>
          {/* <div id="links">
            <h4>Links Úteis</h4>
            <div id="anos">
              <a
                href="http://www.atendimentoabramge.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ABRAMGE
              </a>
              <a
                id="anopassado"
                href="http://www.ans.gov.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ANS
              </a>
            </div>
            <img
              src={require('../../assets/img/anexo-detail.svg')}
              alt="Detalhe"
            />
          </div> */}
        </Anexos>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
