import styled from 'styled-components';

import yellow_detail from '../../assets/img/yellow-detail.svg';
import blue_detail from '../../assets/img/blue-detail.svg';
import hands from '../../assets/img/hands-together.png';

export const Container = styled.div`
  height: 100%;
`;

export const Banner = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  padding-top: 125px;
  margin-top: 0;
  background: #fffff2;

  @media (max-width: 1420px) {
    padding-top: 155px;
  }

  @media (max-width: 900px) {
    padding-top: 115px;
  }

  @media (max-width: 800px) {
    height: auto;
    padding-top: 0px;
    margin-bottom: 0;
    background: #fffff2;
  }

  #banner-mobile {
    display: none;

    width: 100%;
    height: 100%;
    background: #fffff2;
    background-image: url(${hands});
    background-repeat: no-repeat;
    background-size: 100%;
    background-blend-mode: color-burn;
    background-position: left 95%;
    padding-top: 240px;
    padding-bottom: 50px;

    @media (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 450px) {
      padding-top: 180px;
    }

    h2 {
      width: 90%;
      color: #8b839a;
      font-size: 40px;
      line-height: 44px;
      font-weight: 300;
      text-align: left;

      strong {
        color: #019cb6;
        font-weight: 800;
      }
    }
  }

  img {
    display: initial;
    object-fit: fill;
    width: 100%;
    height: 100%;

  

    @media (max-width: 800px) {
      display: none;
      object-position: 30% 0%;
    }
  }
`;

export const Links = styled.div`
  position: relative;
  padding-top: 0px;

  /* @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  } */

  > div {
    background-color: #0094bf;
    padding: 110px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: grid;
      grid-template-columns: repeat(3, 180px);
      grid-column-gap: 100px;
      grid-row-gap: 65px;
      background-color: #0094bf;
      z-index: 1;
      position: relative;

      @media (max-width: 780px) {
        grid-template-columns: repeat(2, 180px);
        grid-column-gap: 65px;
      }

      @media (max-width: 750px) {
        grid-template-columns: repeat(2, 160px);
      }

      @media (max-width: 415px) {
        grid-gap: 25px;
      }

      @media (max-width: 375px) {
        grid-template-columns: repeat(2, 145px);
      }

      #detail {
        position: absolute;
        z-index: 5;
        top: -110px;
        width: 27%;
        right: -35%;
      }

      .bluebox-background {
        position: absolute;
        right: -35%;
        bottom: -110px;
        width: 27%;
        height: 300px;
        background: #0094bf;
        content: '';
      }

      #detail-2 {
        transform: rotate(180deg);
        position: absolute;
        z-index: -1;
        bottom: -360px;
        width: 27%;
        right: -35%;
      }

      a,
      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #f3e82e;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
          margin-bottom: 20px;
          width: 110px;
          height: 110px;
          object-fit: contain;
        }

        p {
          font-size: 26px;
          line-height: 31px;
          font-weight: bold;
          text-align: center;

          @media (max-width: 750px) {
            font-size: 22px;
            line-height: 27px;
          }

          @media (max-width: 375px) {
            font-size: 20px;
            line-height: 25px;
          }
        }

        :nth-child(odd) {
          color: #ffffff;
        }
      }
    }
  }
`;

export const Planos = styled.div`
  padding: 165px 20px 28px 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1420px) {
    padding: 200px 20px 20px 20px;
  }

  @media (max-width: 750px) {
    padding: 150px 20px 20px 20px;
  }

  @media (max-width: 450px) {
    padding: 50px 20px 20px 20px;
  }
  
  a {
    margin-bottom: 40px;
    background-color: #0094bf;
    border-radius: 12px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    line-height: 17px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all ease 0.5s;

    :hover {
      background-color: #f3e82e;
      color: #0094bf;
    }

    @media (max-width: 750px) {
      margin-bottom: 40px;
    }
  }

  .sas {
    width: 100%;
    max-width: 900px;
    padding: 40px 0;
    border-bottom: 1px solid #282b81;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h4 {
      font-size: 30px;
      line-height: 36px;
      color: #282b81;
      font-weight: bold;
      margin-bottom: 40px;
      max-width: 575px;
      text-align: center;

      @media(max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: #282b81;
      text-align: center;
      margin-bottom: 40px;

      span {
        font-weight: bold;
      }

      a {
        margin-left: 4px;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      a {
        margin-bottom: 40px;
        margin-right: 15px;

        @media (max-width: 556px) {
          margin-bottom: 0px;
        }
      }
    }
  }
`;

export const Conheca = styled.div`
  background-color: #f2f2f2;
  padding-top: 20px;

  @media (max-width: 600px) {
    padding-top: 0;
  }

  #nossosplanos {
    padding: 30px 20px 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 600px) {
      padding-top: 50px;
    }

    #planos {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: -20px;
      padding-top: 130px;
      width: 100%;
      position: relative;
      max-width: 900px;

      @media (max-width: 600px) {
        padding-top: 0px;
      }

      #row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: fit-content;
        margin: 50px 0;

        @media (max-width: 850px) {
          flex-direction: column-reverse;
          margin-bottom: 50px;
          transition: all ease 1s;
        }

        #card {
          width: 305px;
          height: 305px;
          background-color: #f3e82e;
          border-radius: 12px;
          background-image: url(${yellow_detail});
          background-repeat: no-repeat;
          background-size: 25%;
          background-position: left 95%;
          position: relative;
          justify-self: flex-end;
          z-index: 1;
          margin-left: 40px;

          @media (max-width: 850px) {
            margin-left: 0;
          }

          h4 {
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            color: #282b81;
            text-align: start;
            max-width: 145px;
            margin-top: 20px;
            margin-left: 30px;
            z-index: 1;
          }

          img {
            position: absolute;
            bottom: 0;
            left: 110px;
          }
        }

        #text {
          max-width: 530px;
          position: relative;
          transition: all ease 1s;

          @media (max-width: 850px) {
            margin-top: 40px;
            max-width: 80%;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            color: #282b81;
            text-align: left;

            @media (max-width: 850px) {
              text-align: center;
            }
          }
        }
      }

      #rowpar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: fit-content;
        margin: 50px 0;

        @media (max-width: 850px) {
          flex-direction: column;
          margin-bottom: 50px;
          transition: all ease 0.5s;
        }

        .detail-image {
          position: absolute;
          width: 27%;
          right: -150px;
          top: -150px;
        }

        #card {
          align-self: center;
          width: 305px;
          height: 305px;
          background-color: #f3e82e;
          border-radius: 12px;
          background-color: #0094bd;
          background-image: url(${blue_detail});
          background-repeat: no-repeat;
          background-size: 40%;
          background-position: right 95%;
          position: relative;
          z-index: 1;

          h4 {
            text-align: end;
            max-width: 165px;
            margin-left: 110px;
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            color: #fff;
            margin-top: 20px;
            z-index: 1;
          }

          img {
            position: absolute;
            right: 120px;
            bottom: 0;
          }
        }

        #textpar {
          max-width: 530px;
          position: relative;
          transition: all ease 1s;

          @media (max-width: 850px) {
            margin-top: 40px;
            max-width: 80%;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }

          .textpar-agendamento {
            margin: 30px 0px;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            color: #282b81;
            text-align: start;

            @media (max-width: 850px) {
              text-align: center;
            }

            a {
              width: fit-content;
              margin-right: 8px;
              margin-bottom: 8px;
              background-color: #0094bf;
              border-radius: 12px;
              font-size: 12px;
              color: #fff;
              font-weight: bold;
              line-height: 24px;
              padding: 10px 20px;
              text-decoration: none;
              transition: all ease 0.5s;

              &:hover {
                background-color: #f3e82e;
                color: #0094bf;
              }

              @media (max-width: 750px) {
                margin-bottom: 14px;
                min-width: 140px;
                max-width: 140px;
              }
            }
          }
        }
      }
    }
  }
`;

export const Beneficiarios = styled.div`
  width: 100%;
  padding-top: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ol {
    list-style: none;
    counter-reset: counter-custom;

    @media (max-width: 600px) {
      width: 90%;
    }

    li::before {
      content: counter(counter-custom) '. ';
      margin-right: 8px;
      background: #282b81;
      padding: 4px;
      padding-left: 8px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      color: #fff;
      font-weight: bold;
    }

    li {
      margin: 8px 0px;
      counter-increment: counter-custom;
      color: #282b81;
      font-size: 16px;
      line-height: 24px;

      a {
        color: inherit;
        font-weight: bold;
        transition: all ease 0.3s;

        &:hover {
          color: inherit;
          opacity: 0.7;
          transition: all ease 0.3s;
        }
      }

      > ol {
        list-style: none;
        counter-reset: counter-custom2;

        .li-2::before {
          content: counter(counter-custom) '. ' counter(counter-custom2);
          margin-right: 8px;
          background: #282b81;
          padding: 8px 6px;
          padding-left: 6px;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          color: #fff;
          font-weight: bold;
          font-size: 12px;
        }

        .li-2 {
          margin: 16px 0px;
          font-size: 16px;
          counter-increment: counter-custom2;
        }
      }
    }
  }

  .line-top {
    position: relative;
    padding-bottom: 120px;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background: #282b81;
    }

    img {
      position: absolute;
      left: -100px;
    }
  }

  .beneficiarios-container {
    position: relative;

    .beneficiarios-text {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      #row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: fit-content;
        margin: 50px 0;

        @media (max-width: 850px) {
          flex-direction: column-reverse;
          align-items: center;

          margin-bottom: 50px;
          transition: all ease 1s;
        }

        #card {
          width: 305px;
          height: 305px;
          border-radius: 12px;
          background-color: #0094bd;
          position: relative;
          justify-self: flex-end;
          z-index: 1;
          margin-left: 40px;

          @media (max-width: 850px) {
            margin-left: 0;
          }

          &::before {
            background-image: url(${blue_detail});
            background-repeat: no-repeat;
            background-size: 40%;
            background-position: 100% 95%;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            transform: scaleX(-1);
          }

          h4 {
            font-size: 30px;
            font-weight: 800;
            line-height: 32px;
            color: #fff;
            text-align: start;
            /* max-width: 145px; */
            margin-top: 50px;
            margin-left: 30px;
            z-index: 1;

            @media(max-width: 750px) {
              font-size: 24px;
              line-height: 30px;
            }
          }

          img {
            position: absolute;
            bottom: 0;
            left: 110px;
          }
        }

        #text {
          max-width: 530px;
          position: relative;
          transition: all ease 1s;

          @media (max-width: 850px) {
            margin-top: 40px;
            max-width: 80%;
          }

          @media (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }
        }
      }

      #rowpar {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: fit-content;
        margin: 50px 0;

        @media (max-width: 850px) {
          flex-direction: column;
          margin-bottom: 50px;
          transition: all ease 0.5s;
        }

        #card {
          align-self: center;
          width: 305px;
          height: 305px;
          background-color: #f3e82e;
          border-radius: 12px;
          position: relative;
          z-index: 1;

          &::before {
            background-image: url(${yellow_detail});
            background-repeat: no-repeat;
            background-size: 30%;
            background-position: 0% 95%;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            transform: scaleX(-1);
          }

          h4 {
            text-align: right;
            margin-left: 90px;
            margin-right: 40px;
            font-size: 30px;
            font-weight: 800;
            line-height: 32px;
            color: #282b81;
            margin-top: 50px;
            z-index: 1;

            @media(max-width: 750px) {
              font-size: 24px;
              line-height: 30px;
            }
          }

          img {
            position: absolute;
            right: 120px;
            bottom: 0;
          }
        }

        #textpar {
          max-width: 530px;
          position: relative;
          transition: all ease 1s;
          margin-left: 50px;

          @media (max-width: 850px) {
            margin-top: 40px;
            margin-left: 0;
            max-width: 80%;
          }

          @media (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }
        }
      }

      h2 {
        color: #282b81;
        font-size: 30px;
        font-weight: bold;

        @media(max-width: 750px) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      p {
        margin-top: 20px;
        margin-bottom: 0;
        color: #282b81;
        font-size: 16px;
        text-align: center;

        a {
          color: inherit;

          &:hover {
            color: inherit;
            opacity: 0.7;
            transition: all ease 0.3s;
          }
        }
      }

      .ol-2 {
        /* margin-bottom: 80px; */
        /* margin-top: 80px; */
        width: 100%;

        @media (max-width: 600px) {
          width: 90%;
        }
      }
    }
  }

  .lei-container {
    padding: 50px 0px;
    background: #f3e82e;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lei-text {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      p {
        width: 100%;
        color: #282b81;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }
`;

export const Inclusao = styled.div`
  width: 100%;
  padding: 90px 0px;
  position: relative;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    max-width: 900px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    img {
      position: absolute;
      top: -90px;
      right: -200px;
    }

    h2 {
      color: #282b81;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 50px;

      @media(max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (max-width: 600px) {
        margin-bottom: 30px;
      }
    }

    p {
      /* margin-top: 50px; */
      color: #282b81;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      width: 100%;

      a {
        color: inherit;

        &:hover {
          color: inherit;
          opacity: 0.7;
          transition: all ease 0.3s;
        }
      }
    }

    > div {
      margin-top: 40px;
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        margin: 0;
        width: fit-content;
      }

      a {
        margin-left: 12px;
        background-color: #0094bf;
        border-radius: 12px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        line-height: 17px;
        padding: 10px 20px;
        text-decoration: none;
        transition: all ease 0.5s;

        &:hover {
          background-color: #f3e82e;
          color: #0094bf;
        }
      }
    }
  }
`;

export const Manual = styled.div`
  width: 100%;
  padding: 100px 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    padding: 50px;
  }

  &::after {
    background: #282b81;
    content: '';
    width: 60%;
    max-width: 900px;
    height: 1px;
    position: absolute;
    top: 0;
  }

  &::before {
    background: #282b81;
    content: '';
    width: 60%;
    max-width: 900px;
    height: 1px;
    position: absolute;
    bottom: 0;
  }

  #card {
    align-self: center;
    width: 305px;
    height: 305px;
    background-color: #f3e82e;
    border-radius: 12px;
    background-color: #0094bd;
    background-image: url(${blue_detail});
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: right 95%;
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      text-align: center;
      max-width: 165px;
      font-size: 32px;
      font-weight: 800;
      line-height: 30px;
      color: #fff;
      margin-top: -50px;
      z-index: 1;

      @media(max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    a {
      background: #1d508a;
      padding: 10px 12px;
      border-radius: 12px;
      color: #fff;
      font-weight: bold;
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.7;
        color: inherit;
        transition: all ease 0.3s;
      }
    }

    img {
      position: absolute;
      right: 200px;
      bottom: 0;

      @media (max-width: 600px) {
        right: 130px;
      }
    }
  }
`;

export const Direitos = styled.div`
  background: #f2f2f2;
  padding-top: 120px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding-top: 50px;
  }

  h3 {
    color: #282b81;
    font-weight: bold;
    font-size: 30px;

    @media(max-width: 750px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  p {
    color: #282b81;
    text-align: center;
    width: 80%;
    font-size: 16px;
    line-height: 30px;
    color: rgb(40, 43, 129);
  }

  ul {
    list-style: none;

    li {
      margin-left: 4px;
      color: #282b81;
      font-size: 16px;
      line-height: 24px;

      p {
        margin: 0px 0px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    li::before {
      content: '';
      border-color: transparent #0094bf;
      border-style: solid;
      border-width: 0.55em 0 0.55em 0.75em;
      display: block;
      height: 0;
      width: 0;
      left: -1.25em;
      top: 1.25em;
      position: relative;
    }
  }

  > div {
    margin: 40px 0px;
    max-width: 790px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 600px) {
      margin: 20px 0px;
    }

    h4 {
      margin-bottom: 20px;
      width: 100%;

      strong {
        width: 80%;
        font-size: 16px;
        font-weight: bold;
        background: #f3e82e;
      }
    }

    p {
      text-align: left;
      width: 100%;
    }
  }

  .atendimento {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      width: fit-content;

      @media (max-width: 600px) {
        width: 100%;
        text-align: center;
      }
    }

    ul {
      width: fit-content;
      display: grid;
      grid-template-columns: repeat(3, 300px);
      grid-gap: 0px;
      margin-left: 200px;

      @media (max-width: 800px) {
        grid-template-columns: 300px 300px;
        margin-left: 140px;
      }

      @media (max-width: 620px) {
        grid-template-columns: 1fr;
        margin-left: 0;
      }

      li {
        width: fit-content;
        color: #282b81;
      }
    }
  }
`;

export const Internacoes = styled.div`
  width: 100%;
  position: relative;
  background: #0094bd;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    max-width: 900px;
    margin: 50px 0px;
    position: relative;
    padding: 0 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-container {
      position: absolute;
      right: -180px;
      top: -50px;
    }

    h4 {
      color: #fff;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 40px;

      @media(max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    p {
      width: 100%;
      color: #fff;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        text-align: center;
      }

      &:last-child {
        margin-top: 30px;
        width: auto;
      }

      a {
        margin-left: 12px;
        background-color: #282b81;
        border-radius: 12px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        line-height: 17px;
        padding: 10px 20px;
        text-decoration: none;
        transition: all ease 0.5s;

        &:hover {
          background-color: #f3e82e;
          color: #0094bf;
        }
      }
    }
  }
`;

export const Exclusao = styled.div`
  width: 100%;
  padding: 100px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  #card {
    align-self: flex-start;
    width: 305px;
    height: 305px;
    background-color: #f3e82e;
    border-radius: 12px;
    background-image: url(${yellow_detail});
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: right 95%;
    position: relative;
    z-index: 1;
    margin-left: 40px;

    @media (max-width: 850px) {
      margin-left: 0;
      margin-bottom: 50px;
      align-self: center;
    }

    h4 {
      font-size: 32px;
      font-weight: 800;
      line-height: 30px;
      color: #282b81;
      text-align: right;
      max-width: 250px;
      margin-top: 40px;
      margin-left: 40px;
      z-index: 1;

      @media(max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      z-index: 5;
      right: 110px;
    }

    img:last-child {
      position: absolute;
      bottom: -293px;
      right: 20px;

      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .exclusao-text {
    text-align: left;
    color: #282b81;
    width: 40%;
    max-width: 550px;
    margin: 0px 50px;

    @media (max-width: 750px) {
      width: 90%;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

    p:last-child {
      margin-top: 30px;
    }

    a {
      background: #0094bf;
      color: #fff;
      padding: 10px 12px;
      border-radius: 12px;
      transition: all ease 0.3s;
      margin-left: 8px;

      &:hover {
        opacity: 0.7;
        color: inherit;
        transition: all ease 0.3s;
      }
    }
  }
`;

export const Perguntas = styled.div`
  width: 100%;
  padding: 100px 0px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    padding: 50px;
  }

  &::after {
    background: #282b81;
    content: '';
    width: 60%;
    max-width: 900px;
    height: 1px;
    position: absolute;
    top: 0;
  }

  &::before {
    background: #282b81;
    content: '';
    width: 60%;
    max-width: 900px;
    height: 1px;
    position: absolute;
    bottom: 0;
  }

  #card {
    align-self: center;
    width: 305px;
    height: 305px;
    background-color: #f3e82e;
    border-radius: 12px;
    background-color: #0094bd;
    background-image: url(${blue_detail});
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: right 95%;
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      text-align: center;
      max-width: 165px;
      font-size: 32px;
      font-weight: 800;
      line-height: 30px;
      color: #fff;
      margin-top: -50px;
      z-index: 1;

      @media (max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    a {
      background: #1d508a;
      padding: 10px 12px;
      border-radius: 12px;
      color: #fff;
      font-weight: bold;
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.7;
        color: inherit;
        transition: all ease 0.3s;
      }
    }

    img {
      position: absolute;
      right: 200px;
      bottom: 0;

      @media (max-width: 600px) {
        right: 150px;
      }
    }
  }
`;

export const Anexos = styled.div`
  padding: 0px 20px 150px 20px;
  background-color: #f2f2f2;

  h4 {
    font-size: 30px;
    line-height: 36px;
    color: #282b81;
    font-weight: bold;
    margin-bottom: 40px;
    max-width: 575px;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 30px;
      max-width: 460px;
    }
  }

  p {
    font-size: 16px;
    line-height: 30px;
    color: #282b81;
    text-align: center;
    margin-bottom: 40px;

    span {
      font-weight: bold;
    }
  }

  a,
  button {
    background-color: #0094bf;
    border-radius: 12px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    line-height: 17px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all ease 0.5s;
    border: none;

    :hover {
      background-color: #282b81;
    }
  }

  button {
    margin-top: 25px;
    cursor: pointer;
  }

  #formulario {
    display: flex;
    margin-bottom: 40px;
  }

  #idss {
    a {
      margin: 5px 10px;
      padding: 10px 40px;
    }
  }

  .idss {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  #anos {
    a {
      margin: 0 10px;
      padding: 10px 40px;
    }

    #anopassado {
      background-color: #282b81;

      :hover {
        background-color: #0094bf;
      }
    }
  }

  #servico-de-atendimento-ao-beneficiario {
    padding-top: 150px;
    margin-top: -150px;
  }

  > div {
    max-width: 900px;
    padding: 40px 0;
    border-bottom: 1px solid #282b81;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  #links {
    border: none;
    position: relative;

    img {
      position: absolute;
      bottom: -325px;
      left: -200px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      align-self: flex-start;
    }

    #inputs {
      display: flex;
      width: 100%;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      > div {
        display: flex;
        flex-direction: column;
        width: 32%;
        margin: 10px 5px;

        @media (max-width: 600px) {
          width: 100%;
          margin: 5px 0px;
        }
      }
    }

    input {
      width: 100%;
      height: 35px;

      ::-webkit-input-placeholder {
        color: #0094bf;
      }
    }

    textarea {
      width: 98%;
      padding-top: 15px;
      resize: none;
      height: 155px;

      @media (max-width: 600px) {
        width: 100%;
        margin-top: 5px;
      }

      ::-webkit-input-placeholder {
        color: #0094bf;
      }
    }

    input,
    textarea {
      background-color: transparent;
      border: 1px solid #0094bf;
      border-radius: 12px;
      padding-left: 15px;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      color: #282b81;
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
