import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';
import { IoMdAttach, IoMdClose } from 'react-icons/io';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// styels
import { Container, Content } from './style';

// api
import ademail from '../../services/ademail';

export default function TrabalheConosco() {
  const [disabled, setDisabled] = useState(false);
  const [erroFile, setErroFile] = useState();
  const [file, setFile] = useState();
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                <p>Nome: ${values.nome}</p>
                <p>E-mail: ${values.email}</p>
                <p>Telefone: ${values.telefone}</p>
                <small>Currículo em anexo</small>
              `;

      const attach = file;
      const mail = new FormData();

      mail.append('to', 'recrutamento@santacasapg.com');
      mail.append('from', values.email);
      mail.append('as', values.nome);
      // mail.append('cc', JSON.stringify(['web@agenciaade.com.br']));
      mail.append('subject', 'Novo envio de currículo via site - Santa Casa');
      mail.append('message', body);
      if (attach) mail.append('attach', attach);

      const config = {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'content-type': 'multipart/form-data',
        },
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        if (file !== undefined) {
          await ademail.post('/email', mail, config);

          notification.success({
            message: 'Contato enviado com sucesso! 🚀',
            placement: 'bottomRight',
          });

          resetForm();
          setFile();
          setErroFile();
          setDisabled(false);
        } else {
          setErroFile('Escolha um arquivo');
        }
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Fale com a Santa Casa</title>
        <meta
          name="description"
          content="(42) 3026-8000 – sac@scmpg.org.br – Av. Doutor Francisco Burzio, 774, Centro – Ponta Grossa – PR"
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-contato-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content>
          <h4>Trabalhe conosco</h4>
          <div id="formulario">
            <form
              key="form-trabalhe"
              onSubmit={formik.handleSubmit}
              encType="multipart/formdata"
            >
              <div id="inputs">
                <div>
                  <input
                    type="text"
                    name="nome"
                    placeholder="* NOME COMPLETO"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div>
                  <Input
                    type="tel"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    placeholder="* TELEFONE"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="* E-MAIL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="file" className={file ? 'label-file' : ''}>
                    <IoMdAttach /> ANEXAR CURRÍCULO
                  </label>
                  <input
                    hidden
                    type="file"
                    name="file"
                    id="file"
                    onChange={(event) => {
                      setFile(event.currentTarget.files[0]);
                      setErroFile();
                    }}
                  />
                  {erroFile && <span>Escolha um arquivo</span>}
                </div>
                {file && (
                  <div className="arquivo">
                    <p>{file.name}</p>
                    <button
                      type="button"
                      onClick={() => {
                        setFile();
                      }}
                    >
                      <IoMdClose />
                    </button>
                  </div>
                )}
              </div>
              <button type="submit" className={disabled ? 'disable' : ''}>
                ENVIAR
              </button>
            </form>
          </div>
          <div id="links">
            <a href="tel:+554230268000">(42) 3026-8000 - Geral</a>
            <a href="tel:+554230268001">
              (42) 3026-8001 - Centro de Especialidades
            </a>
            <a href="tel:+554230268004">(42) 3026-8004 – Santa Casa Saúde</a>
            <a href="tel:+554230268007">
              (42) 3026-8007 - Centro Avançado de Diagnósticos
            </a>
            <a href="tel:+554230268080">(42) 3026-8080 - Laboratório</a>
            <a href="mailto:sac@santacasapg.com" id="email">
              recrutamento@santacasapg.com
            </a>
            <a
              id="endereco"
              href="https://www.google.com.br/maps/place/Santa+Casa+de+Misericordia+-Ponta+Grossa/@-25.0891505,-50.1617429,17z/data=!3m1!4b1!4m2!3m1!1s0x94e81a3e30d25e43:0x91edf1be1ab9cde4?hl=pt-BR"
            >
              Av. Doutor Francisco Burzio, 774 <br />
              Centro - 84010-200 <br />
              Ponta Grossa - PR
            </a>
            <div id="icons">
              <a
                href="https://www.facebook.com/santacasapg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/facebook-icon.svg')}
                  alt="Facebook"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCZhVrjoLCHNk9mhQ-0mg7gg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/youtube-icon.svg')}
                  alt="Youtube"
                />
              </a>
            </div>
          </div>
          <iframe
            id="mapa"
            title="mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.4421811876127!2d-50.16373867819902!3d-25.088944846662933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91edf1be1ab9cde4!2sSanta%20Casa%20de%20Miseric%C3%B3rdia%20Ponta%20Grossa!5e0!3m2!1spt-BR!2sbr!4v1590418164014!5m2!1spt-BR!2sbr"
            width="100%"
            height="350"
            frameBorder="0"
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Content>
        <Footer />
      </Container>
    </>
  );
}
