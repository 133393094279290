import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../pages/Home';
import Institucional from '../pages/Institucional';
import Planodesaude from '../pages/Planodesaude';
import Laboratorio from '../pages/Laboratorio';
import Ensinoepesquisa from '../pages/Ensinoepesquisa';
import Contato from '../pages/Contato';
import Centrodeespecialidades from '../pages/Centrodeespecialidades';
import Diagnosticos from '../pages/Diagnosticos';
import Outrosservicos from '../pages/Outrosservicos';
import TrabalheConosco from '../pages/TrabalheConosco';
import Sas from '../pages/Sas';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/institucional" exact component={Institucional} />
      <Route path="/plano-de-saude" exact component={Planodesaude} />
      <Route path="/sas" exact component={Sas} />
      <Route path="/laboratorio" exact component={Laboratorio} />
      <Route path="/ensino-e-pesquisa" exact component={Ensinoepesquisa} />
      <Route path="/contato" exact component={Contato} />
      <Route path="/trabalhe-conosco" exact component={TrabalheConosco} />
      <Route
        path="/centro-de-especialidades"
        exact
        component={Centrodeespecialidades}
      />
      <Route
        path="/centro-avancado-de-diagnosticos"
        exact
        component={Diagnosticos}
      />
      <Route path="/outros-servicos" exact component={Outrosservicos} />
      <Route exact path="/site">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
