import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Banner = styled.div`
  height: 665px;
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  @media (max-width: 750px) {
    height: 450px;
  }

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-slide {
    width: 100%;
    height: 665px;
    position: relative;

    @media (max-width: 750px) {
      height: 450px;
    }
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #1692bd;
  }

  .ant-carousel .slick-dots li button {
    background: #fff;
    opacity: 1;
  }

  .ant-carousel {
    margin-left: 0px;
  }

  .slick-slide {
    > div {
      height: 665px;
      display: flex;
      align-items: center;

      @media (max-width: 750px) {
        height: 450px;
      }
    }
  }

  #slider {
    padding-left: 13.5%;
    width: 100%;
    height: 665px;

    @media (max-width: 850px) {
      padding-left: 80px;
    }

    @media (max-width: 750px) {
      height: 450px;
    }

    @media (max-width: 485px) {
      padding-left: 0;
    }

    #bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 15% top;

        @media (max-width: 550px) {
          object-position: 30% top;
        }
      }
    }

    p {
      font-size: 58px;
      font-weight: 300;
      line-height: 69px;
      color: #282b81;
      max-width: 665px;
      margin: 0;
      padding: 240px 20px 0 20px;

      @media (max-width: 750px) {
        font-size: 30px;
        line-height: 41px;
        max-width: 365px;
        padding-top: 140px;
      }

      @media (max-width: 485px) {
        margin: auto;
        max-width: 100%;
      }

      @media (max-width: 365px) {
        font-size: 28px;
        line-height: 39px;
      }

      @media (max-width: 340px) {
        font-size: 26px;
        line-height: 37px;
      }

      strong {
        font-weight: 900;
        color: #1692bd;
      }
    }
  }
`;

export const Serviços = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;

  @media (max-width: 1380px) {
    margin-top: 150px;
  }

  @media (max-width: 905px) {
    margin-top: 110px;
  }

  @media (max-width: 905px) {
    margin-top: 80px;
  }

  #text {
    background-color: #f3e82e;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 995px) {
      padding-bottom: 50%;
    }

    @media (max-width: 750px) {
      padding-top: 60px;
      padding-bottom: 80%;
    }

    @media (max-width: 500px) {
      padding-bottom: 95%;
    }

    #pessoas {
      position: absolute;
      bottom: 0;
      right: 5%;
      z-index: 1;
      width: 50%;

      @media (max-width: 995px) {
        right: inherit;
      }

      @media (max-width: 750px) {
        width: 80%;
      }

      @media (max-width: 500px) {
        width: 95%;
      }
    }

    h3 {
      font-size: 42px;
      font-weight: 300;
      line-height: 52px;
      color: #282b81;
      width: 60%;
      z-index: 2;

      @media (max-width: 870px) {
        width: 80%;
      }

      @media (max-width: 750px) {
        font-size: 22px;
        line-height: 32px;
      }

      @media (max-width: 345px) {
        width: 90%;
      }

      span {
        font-weight: 900;
      }
    }

    #detalhe {
      margin-top: 40px;
      padding-left: 28.5%;
      align-self: flex-start;
      z-index: 0;

      @media (max-width: 1200px) {
        width: 450px;
        height: 300px;
      }

      @media (max-width: 995px) {
        display: none;
      }
    }
  }

  #serviços {
    display: grid;
    grid-template-columns: repeat(4, 260px);
    grid-gap: 40px;
    position: relative;
    top: -130px;
    z-index: 5;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 220px);
      grid-gap: 25px;
      top: -110px;
    }

    @media (max-width: 995px) {
      grid-template-columns: repeat(2, 220px);
    }

    @media (max-width: 505px) {
      grid-template-columns: repeat(1, 220px);
    }

    a {
      text-decoration: none;
      border-radius: 12px;
      position: relative;
      background-color: transparent;

      #servico-img {
        width: 260px;
        height: 260px;
        border-radius: 12px;

        @media (max-width: 1200px) {
          width: 220px;
          height: 220px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }

      p {
        font-size: 14px;
        line-height: 17px;
        color: #282b81;
        padding: 5px;
        text-align: center;
      }

      #hover-detail {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(255, 255, 255, 0.8);
        transition: all ease 0.5s;
        border-radius: 12px;
        opacity: 0;

        img {
          width: 55%;
          height: auto;
          margin: 87.305px 58.5px;

          @media (max-width: 1200px) {
            margin: 73.875px 49.5px;
          }
        }
      }

      :hover {
        #hover-detail {
          opacity: 1;
        }
      }
    }
  }
`;

export const Frase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 340px) {
    margin-bottom: 50px;
  }

  #texto {
    margin-right: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 600px) {
      width: 60%;
    }

    @media (max-width: 470px) {
      width: 70%;
      margin-right: 20px;
    }

    @media (max-width: 400px) {
      width: 80%;
    }

    @media (max-width: 340px) {
      width: 100%;
      padding: 0 20px;
      margin-right: 0;
    }

    h4 {
      font-size: 42px;
      font-weight: 300;
      line-height: 52px;
      color: #282b81;
      max-width: 520px;
      text-align: end;
      padding-left: 20px;

      @media (max-width: 1000px) {
        font-size: 32px;
        line-height: 42px;
        max-width: 400px;
      }

      @media (max-width: 825px) {
        font-size: 22px;
        line-height: 32px;
        max-width: 295px;
      }

      @media (max-width: 400px) {
        max-width: 275px;
        font-size: 20px;
        line-height: 30px;
      }

      @media (max-width: 340px) {
        max-width: 270px;
      }

      span {
        font-weight: 900;
      }
    }

    p {
      font-size: 25px;
      line-height: 25px;
      color: #282b81;
      margin-top: 30px;
      text-align: end;

      @media (max-width: 825px) {
        font-size: 18px;
        line-height: 18px;
        margin-top: 20px;
      }
    }
  }

  #detalhes {
    width: 50%;
    position: relative;

    @media (max-width: 600px) {
      width: 20%;
    }

    @media (max-width: 340px) {
      display: none;
    }

    #colorido {
      width: 170px;
      height: auto;
      margin-right: 30px;
      position: relative;
      top: 70px;
      transform: rotate(180deg);

      @media (max-width: 1000px) {
        width: unset;
      }

      @media (max-width: 825px) {
        width: 20%;
        top: 45px;
      }

      @media (max-width: 600px) {
        width: 60%;
        margin-right: 0;
      }

      @media (max-width: 470px) {
        width: 55px;
        height: auto;
      }

      @media (max-width: 340px) {
        display: none;
      }
    }

    #amarelo {
      position: relative;
      top: -150px;

      @media (max-width: 1000px) {
        width: 30%;
        top: -65px;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
`;
