import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding-top: 125px;
  scroll-behavior: smooth;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: 50px auto;
    padding: 0 20px;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 600px) {
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    span {
      font-weight: bold;
    }
  }

  #formulario {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 20px;

    .react-tabs__tab-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      margin-top: 30px;
    }
    .react-tabs__tab {
      padding: 15px;
      color: #666;
      font-weight: 600;
    }
    .react-tabs__tab--selected {
      color: #282b81;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        color: #df5960;
        font-weight: 600;
        font-size: 12px;
      }

      #inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 32%;
          margin: 10px 5px;

          @media (max-width: 600px) {
            width: 100%;
            margin: 5px 0px;
          }
        }
      }

      input {
        width: 100%;
        height: 35px;
        color: #282b81;
        ::-webkit-input-placeholder {
          color: #0094bf;
        }
      }

      textarea {
        width: 98%;
        padding-top: 15px;
        resize: none;
        height: 155px;
        color: #282b81;

        @media (max-width: 600px) {
          width: 100%;
          margin-top: 5px;
        }

        ::-webkit-input-placeholder {
          color: #0094bf;
        }
      }

      label {
        height: 35px;
        color: #0094bf;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.5s;

        :hover {
          color: #282b81;
        }

        svg {
          transform: scale(1.5);
          margin-right: 5px;
        }
      }

      .label-file {
        color: #282b81;
      }

      input,
      textarea,
      label {
        background-color: transparent;
        border: 1px solid #0094bf;
        border-radius: 12px;
        padding-left: 15px;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
      }

      .arquivo {
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 14px;
          color: #000;
          width: fit-content;
          margin: 0;
        }

        button {
          background-color: #fff;
          width: fit-content;
          margin-top: 0;
          color: #000;
          position: relative;
          top: 1px;
          padding: 10px 0 10px 20px;

          svg {
            transform: scale(1.5);
          }

          :hover {
            background-color: #fff;
            transform: scale(1.3);
          }
        }
      }

      button {
        background-color: #0094bf;
        border-radius: 12px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        line-height: 17px;
        padding: 10px 20px;
        text-decoration: none;
        transition: all ease 0.5s;
        border: none;
        margin-top: 25px;
        cursor: pointer;

        :hover {
          background-color: #282b81;
        }
      }

      .disable {
        z-index: -1;
      }
    }
  }

  #links {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    border-left: 2px solid #2d2e92;
    padding-left: 30px;
    margin: auto;
    margin-top: 75px;

    @media (max-width: 460px) {
      margin: 75px 20px 0 20px;
      padding-left: 20px;
    }

    a {
      font-size: 17px;
      line-height: 20px;
      color: #282b81;
      font-weight: bold;
    }

    #email,
    #endereco {
      font-weight: 500;
      margin: 25px 0;
    }

    #endereco {
      margin-top: 0;
      margin-bottom: 20px;
    }

    #icons {
      a {
        margin: 0 5px;

        img {
          width: 20px;
          height: auto;
        }
      }
    }
  }

  #mapa {
    position: relative;
    bottom: -6px;
    margin-top: 45px;
  }
`;
