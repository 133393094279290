import React, { useState } from 'react';

// styles
import { Navbar } from './style';

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <Navbar isMenuOpen={isMenuOpen}>
      <div id="content">
        <div id="logo">
          <a href="/" id="logo">
            <img src={require('../../assets/logo.svg')} alt="Logo Santa Casa" />
          </a>
          <img
            id="detail"
            src={require('../../assets/logo-detail.svg')}
            alt="Detalhe"
          />
        </div>
        <nav>
          <ul>
            <li id="home">
              <a href="/">HOME</a>
              <div id="detail" />
            </li>
            <li id="institucional">
              <a href="/institucional">INSTITUCIONAL</a>
              <div id="detail" />
            </li>
            <li id="plano">
              <a href="/plano-de-saude">PLANO DE SAÚDE</a>
              <div id="detail" />
            </li>
            <li id="diagnóstico">
              <a href="/centro-avancado-de-diagnosticos">
                CENTRO AVANÇADO DE DIAGNÓSTICOS
              </a>
              <div id="detail" />
            </li>
            <li id="especialidades">
              <a href="/centro-de-especialidades">CENTRO DE ESPECIALIDADES</a>
              <div id="detail" />
            </li>
            <li id="laboratorio">
              <a href="/laboratorio">LABORATÓRIO</a>
              <div id="detail" />
            </li>
            <li id="outros">
              <a href="/outros-servicos">OUTROS SERVIÇOS</a>
              <div id="detail" />
            </li>
            <li id="ensino">
              <a href="/ensino-e-pesquisa">ENSINO E PESQUISA</a>
              <div id="detail" />
            </li>
            <li id="sas">
              <a href="/sas">SAS</a>
              <div id="detail" />
            </li>
            <li id="contato">
              <a href="/contato">CONTATO</a>
              <div id="detail" />
            </li>
          </ul>
        </nav>
        <button
          className={` hamburger hamburger--collapse ${
            isMenuOpen && 'is-active'
          }`}
          type="button"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <div id="bottom-detail" />
    </Navbar>
  );
}
