import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding-top: 125px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: #282b81;
    text-align: center;
    margin: 80px auto 50px auto;
    padding: 0 20px;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 0;
    }

    @media (max-width: 600px) {
      margin-top: 120px;
    }

    @media (max-width: 450px) {
      margin-top: 70px;
    }

    span {
      font-weight: bold;
    }
  }

  #rowpar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 65px 20px 75px 20px;

    @media (max-width: 930px) {
      flex-direction: column;
    }

    #imagem {
      width: 260px;
      height: 260px;
      border-radius: 12px;

      @media (max-width: 930px) {
        margin-top: 30px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    #text {
      max-width: 580px;
      margin-right: 45px;

      @media (max-width: 930px) {
        margin-right: 0px;
      }

      h2 {
        text-align: end;
        margin-bottom: 25px;
        font-size: 30px;
        line-height: 22px;
        color: #282b81;
        font-weight: bold;

        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 36px;
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: #282b81;
        text-align: end;

        @media (max-width: 850px) {
          text-align: center;
        }

        strong {
          font-weight: bold;
        }
      }

      h2,
      p {
        @media (max-width: 930px) {
          text-align: center;
        }
      }
    }
  }

  #rowfirst {
    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }

    #text {
      @media (max-width: 1200px) {
        margin-left: 0px !important;
      }
    }
    h2,
    p {
      @media (max-width: 1200px) {
        text-align: center !important;
      }
    }
  }

  #rowfirst,
  #row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 65px 20px 60px 20px;

    @media (max-width: 930px) {
      flex-direction: column-reverse;
    }

    #imagem {
      width: 260px;
      height: 260px;
      border-radius: 12px;

      @media (max-width: 930px) {
        margin-top: 30px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    #text {
      max-width: 580px;
      margin-left: 45px;

      @media (max-width: 930px) {
        margin-left: 0px;
      }

      h2 {
        text-align: start;
        font-size: 30px;
        line-height: 22px;
        color: #282b81;
        font-weight: bold;
        margin-bottom: 25px;

        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 36px;
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: #282b81;
        text-align: start;

        strong {
          font-weight: bold;
        }
      }

      h2,
      p {
        @media (max-width: 930px) {
          text-align: center;
        }
      }
    }
  }

  #pediatriavideo {
    background-color: #f2f2f2;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
      margin-top: 30px;
    }

    #videodiv {
      position: relative;

      video {
        @media (max-width: 700px) {
          width: 100% !important;
          height: auto !important;
        }
      }

      img {
        position: absolute;
      }
    }

    #playbtn {
      position: absolute;
      width: 560px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      opacity: 1;
      transition: all ease 0.5s;

      @media (max-width: 700px) {
        padding: 0 20px;
        width: 100%;
      }

      #bg {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
      }

      label {
        cursor: pointer;
        transition: all ease 0.5s;
        z-index: 1;
        position: absolute;

        @media (max-width: 700px) {
          width: 35%;
          height: auto;
        }

        img {
          position: relative;

          @media (max-width: 700px) {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        :hover {
          transform: scale(0.9);
        }
      }

      input {
        display: none;
      }
    }

    .remove {
      opacity: 0 !important;
    }

    .nodisplay {
      display: none !important;
    }
  }

  #convenio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%;

    h4 {
      margin: 80px auto 50px auto;
      padding: 0 20px;

      @media (max-width: 750px) {
        margin-bottom: 50px;
      }

      @media (max-width: 600px) {
        margin-top: 80px;
      }

      @media (max-width: 450px) {
        margin-top: 80px;
      }
    }

    p {
      font-size: 16px;
      color: #282b81;
      line-height: 30px;
    }

    #convenios {
      display: grid;
      grid-template-columns: repeat(2, 260px);
      position: relative;

      @media (max-width: 560px) {
        grid-template-columns: 100%;
        width: 100%;
      }

      a {
        font-size: 16px;
        line-height: 30px;
        color: #282b81;

        :hover {
          color: #0094bf;
        }

        img {
          margin-right: 10px;
        }
      }

      #detail {
        position: absolute;
        left: -200px;
        transform: rotate(180deg);
        bottom: -130px;
        z-index: -1;

        @media (max-width: 1050px) {
          bottom: -216px;
        }
      }
    }

    #consulte {
      max-width: 240px;
      position: absolute;
      right: -255px;
      bottom: 30px;

      @media (max-width: 1050px) {
        display: none;
      }

      p,
      a {
        margin-bottom: 0;
        font-size: 16px;
        color: #282b81;
        line-height: 22px;
      }

      a {
        font-weight: bold;
      }
    }
  }

  #consulte2 {
    max-width: 240px;
    position: relative;
    display: none;
    margin-top: 20px;

    @media (max-width: 1050px) {
      display: inline;
    }

    p,
    a {
      margin-bottom: 0;
      font-size: 16px;
      color: #282b81;
      line-height: 22px;
    }

    a {
      font-weight: bold;
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
