/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// style
import { Container, Content, Loader } from './style';

// api
import api from '../../services/api';

export default function Ensinoepesquisa() {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [idactive, setIdactive] = useState(false);
  const [concursos, setConcursos] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function loadConcursos() {
      await api.get(`/pages?slug=ensino-e-pesquisa`).then((response) => {
        setConcursos(response.data[0]);
        setLoading(false);
      });
    }
    loadConcursos();
  }, []);

  return (
    <>
      <Helmet>
        <title>Ensino e Pesquisa</title>
        <meta
          name="description"
          content="Residência Médica, Especialização e Hospital de Ensino."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-ensino_e_pesquisa-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content className={loading ? 'nodisplay' : ''}>
          <h4>
            Ensino e Pesquisa
            <img
              src={require('../../assets/img/lab-detail.svg')}
              alt="Detalhe"
            />
          </h4>
          <div>
            {ReactHtmlParser(!!concursos && concursos.content.rendered)}
          </div>

          <div id="informacoes">
            <img
              src={require('../../assets/img/ensino-detail.svg')}
              alt="Detalhe"
            />
            <div id="cards">
              {!!concursos &&
                concursos.acf.itens.map((item) => (
                  <div>
                    <h4>{item.titulo}</h4>
                    <a
                      href="#conteudo"
                      onClick={() => {
                        setInfoOpen(true);
                        setIdactive(concursos.acf.itens.indexOf(item));
                      }}
                    >
                      SAIBA MAIS
                    </a>
                  </div>
                ))}
            </div>
            {isInfoOpen === true && (
              <div id="conteudo">
                <h4>{concursos.acf.itens[idactive].titulo}</h4>
                {ReactHtmlParser(concursos.acf.itens[idactive].conteudo)}
              </div>
            )}
          </div>
        </Content>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
