/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification, Modal } from 'antd';
import * as Yup from 'yup';
import ReactHtmlParser from 'react-html-parser';
// import { AiOutlineClose } from 'react-icons/ai';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// style
import { Container, Links, Planos, Conheca, Anexos, Loader } from './style';

// api
import ademail from '../../services/ademail';
import api from '../../services/api';

export default function Planodesaude() {
  const [dados, setDados] = useState();
  const [loading, setLoading] = useState(true);
  const [modalReajuste, setModalReajuste] = useState(false);
  const [modalPlanoSaude, setModalPlanoSaude] = useState(false);
  const [modalClientesParana, setModalClientesParana] = useState(true);

  useEffect(() => {
    async function loadDados() {
      await api.get(`/planos`).then((response) => {
        setDados(response.data);
        setLoading(false);
      });
    }
    loadDados();
  }, []);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <p>Nome: ${values.nome}</p>
            <p>E-mail: ${values.email}</p>
            <p>Telefone: ${values.telefone}</p>
            <p>Mensagem: ${values.mensagem}</p>
          `;

      const mail = {
        to: 'ouvidoria@santacasapg.com',
        from: values.email,
        as: values.nome,
        // bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Nova ouvidoria via site - Santa Casa',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Planos de Saúde Santa Casa</title>
        <meta
          name="description"
          content="O Plano Santa Casa Saúde é próprio da Santa Casa de Ponta Grossa, e assegura aos seus beneficiários a garantia de atendimento no hospital mais bem equipado e moderno da região."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-plano-de-saude-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Links className={loading ? 'nodisplay' : ''}>
          <div>
            <div>
              <img
                id="detail"
                src={require('../../assets/img/links-detail.svg')}
                alt="Detalhe"
              />
              <a
                href="http://200.146.34.139:8088/PlanodeSaude/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/guiamedico.svg')}
                  alt="Guia Médico Atualizado"
                />
                <p>Guia Médico Atualizado</p>
              </a>
              <a
                href="http://200.146.34.139:8088/PlanodeSaude/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/boleto.svg')}
                  alt="2a Via Boleto"
                />
                <p>
                  2<sup>a</sup> Via Boleto
                </p>
              </a>
              <a
                href="http://200.146.34.139:8088/PlanodeSaude/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/relatorio.svg')}
                  alt="Relatório de Coparticipação"
                />
                <p>Relatório de Coparticipação</p>
              </a>
              <a
                href="http://200.146.34.139:8088/PlanodeSaude/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/imposto.svg')}
                  alt="Declaração de Imposto de Renda"
                />
                <p>Declaração de Imposto de Renda</p>
              </a>
              <button type="button" onClick={() => setModalReajuste(true)}>
                <img
                  src={require('../../assets/img/reajuste.svg')}
                  alt="Reajuste"
                />
                <p>Reajuste</p>
              </button>
              <a href="#servico-de-atendimento-ao-beneficiario">
                <img
                  src={require('../../assets/img/atendimento.svg')}
                  alt="Serviço de Atendimento ao Beneficiário"
                />
                <p>Serviço de Atendimento ao Beneficiário</p>
              </a>
            </div>
          </div>
        </Links>
        <Planos className={loading ? 'nodisplay' : ''}>
          <div id="title">
            <h4>Planos de Saúde Santa Casa</h4>
            <p>ANS nº 41933-8</p>
          </div>
          <a
            href="http://ops2.santacasapg.com:8088/PlanodeSaude/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACESSE O PORTAL DO USUÁRIO
          </a>
          <a
            href="http://ops1.santacasapg.com:8088/PlanodeSaude/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACESSE O PORTAL DO USUÁRIO (link alternativo)
          </a>
          <div id="texto">
            <img
              src={require('../../assets/img/planos-detail.svg')}
              alt="Detalhe"
            />
            <p>
              O Plano Santa Casa Saúde é próprio da Santa Casa de Ponta Grossa,
              e assegura aos seus beneficiários a garantia de atendimento no
              hospital mais bem equipado e moderno da região.
            </p>
            <p>
              O Santa Casa Saúde oferece aos seus usuários assistência
              médico-hospitalar com cobertura nas mais diversas especialidades
              médicas, contando com uma equipe médica credenciada e centralizada
              em um só local para melhor atendê-lo.
            </p>
            <p>
              As modalidades de planos oferecidos pelo Santa Casa Saúde são: o
              coparticipativo de 50% (cinquenta por cento) e coparticipativo de
              20% (vinte por cento), ou seja, o beneficiário ajuda a custear as
              despesas de seu tratamento/atendimento médico nos índices
              indicados. Essa coparticipação recairá apenas para os atendimentos
              realizados ambulatorialmente (consultas, exames, etc.)
            </p>
          </div>
        </Planos>
        <Conheca className={loading ? 'nodisplay' : ''}>
          <div id="normativa">
            <h4>Resolução Normativa - RN nº 389, de 26 de novembro de 2015</h4>
            <a
              href={require('../../assets/pdf/RN389.pdf')}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              CLIQUE AQUI
            </a>
          </div>
          <div id="nossosplanos">
            <h4>
              Conheça nossos planos{' '}
              <img
                src={require('../../assets/img/nossosplanos-detail.svg')}
                alt="Detalhe"
                id="detailnossosplanos"
              />
            </h4>
            <div id="planos">
              {!!dados &&
                dados.map((plano) => {
                  if (dados.indexOf(plano) % 2 === 0) {
                    return (
                      <div id="row">
                        <div id="text" name="text">
                          <p>{ReactHtmlParser(plano.acf.descricao)}</p>
                        </div>
                        <div id="card">
                          <h4>{plano.title.rendered}</h4>
                          <img
                            src={plano.acf.imagem.sizes.thumbnail}
                            alt="Icon"
                          />
                        </div>
                      </div>
                    );
                  } else
                    return (
                      <div id="rowpar">
                        <div id="card">
                          <h4>{plano.title.rendered}</h4>
                          <img
                            src={plano.acf.imagem.sizes.thumbnail}
                            alt="Icon"
                          />
                        </div>
                        <div id="textpar" name="text">
                          <p>{ReactHtmlParser(plano.acf.descricao)}</p>
                        </div>
                      </div>
                    );
                })}
            </div>
          </div>
        </Conheca>
        <Anexos className={loading ? 'nodisplay' : ''}>
          <div>
            <h4>
              Anexo I Resolução Normativa - RN Nº 389, de 26 de novembro de 2015
            </h4>
            <p>
              Planos individuais ou familiares: São aqueles contratados
              diretamente pelo beneficiário, com ou sem seu grupo familiar.
            </p>
            <p>
              Planos de saúde coletivos: Se dividem em empresarial e coletivo
              por adesão. Os empresarias são contratados em decorrência de
              vínculo empregatício para seus funcionários. Os coletivos por
              adesão são contratados por pessoa jurídica de caráter
              profissional, classista ou setorial para seus vinculados
              (associados ou sindicalizados, por exemplo). Na contratação destes
              planos pode haver a participação de Administradoras de Benefícios.
            </p>
            <a
              href={require('../../assets/pdf/Anexo-I-RN389.pdf')}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              LEIA +
            </a>
          </div>
          <div id="servico-de-atendimento-ao-beneficiario">
            <h4>Serviço de Atendimento ao Beneficiário</h4>
            <p>
              Caro beneficiário, para solicitar reembolso ou liberações,
              registrar reclamações, enviar sugestões ou elogios, use o
              formulário disponível no link abaixo:
            </p>
            <a
              id="formulario"
              href={require('../../assets/pdf/OPS-010.pdf')}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              FORMULÁRIO
            </a>
            <p id="proceder">
              <span>COMO PROCEDER:</span>
            </p>
            <p>
              <span>I.</span> Imprima o formulário; <br />
              <span>II.</span> Preencha de próprio punho os campos necessários
              para seu tipo de solicitação;
              <br /> <span>III.</span> Anexe os demais documentos aplicáveis a
              sua demanda (Notas fiscais, recibos, declarações, pedidos médicos,
              receitas, etc); <br />
              <span>IV.</span> Scanear ou fotografar o material e enviar para o
              email: santacasasaude@santacasapg.com . Caso você não tenha acesso
              a uma conta de email, entrar em contato através do (42) 3026 8004
              para receber instruções.
            </p>
          </div>
          <div id="idss">
            <h4>Resultado IDSS </h4>
            <div className="idss" id="idss" style={{ display: 'flex' }}>
              <a
                href={require('../../assets/pdf/idss-2018.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                IDSS 2018 (Ano Base 2017)
              </a>
              <a
                href={require('../../assets/pdf/idss-2019-v2.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                IDSS 2019 (Ano Base 2018)
              </a>
              <a
                href={require('../../assets/pdf/idss-2020.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                IDSS 2020 (Ano Base 2019)
              </a>
              <a
                href="http://www.ans.gov.br/qualificacao_consumidor/informacoes_operadora.asp?co_operadora_param=419338&cd_processamento_param=201"
                target="_blank"
                rel="noopener noreferrer"
              >
                Site ANS
              </a>
            </div>
          </div>
          <div>
            <h4>Transparência – Demonstrações Contábeis</h4>
            <div id="anos">
              <a
                href={require('../../assets/pdf/demcon2018.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                2018
              </a>
              <a
                id="anopassado"
                href={require('../../assets/pdf/demcon2019.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                2019
              </a>
              <a
                href={require('../../assets/pdf/demcon2020.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                2020
              </a>
            </div>
          </div>
          <div>
            <h4>Fale com a Ouvidoria</h4>
            <form onSubmit={formik.handleSubmit}>
              <div id="inputs">
                <div>
                  <input
                    type="text"
                    name="nome"
                    placeholder="* NOME COMPLETO"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div>
                  <Input
                    type="tel"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    placeholder="* TELEFONE"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="* E-MAIL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>
              </div>
              <textarea
                name="mensagem"
                cols="30"
                rows="10"
                placeholder="MENSAGEM"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
              <button type="submit">ENVIAR</button>
            </form>
          </div>
          <div id="links">
            <h4>Links Úteis</h4>
            <div id="anos">
              <a
                href="http://www.atendimentoabramge.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ABRAMGE
              </a>
              <a
                id="anopassado"
                href="http://www.ans.gov.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ANS
              </a>
            </div>
            <img
              src={require('../../assets/img/anexo-detail.svg')}
              alt="Detalhe"
            />
          </div>
        </Anexos>
        <Modal
          visible={modalReajuste}
          onCancel={() => setModalReajuste(false)}
          footer={false}
          width="900px"
        >
          <div className="modal">
            <h3>Reajustes</h3>
            <div>
              <h4>Contratos Pessoa Física</h4>
              <p>
                O reajuste para pessoas físicas é regulado pela Agência Nacional
                de Saúde, que disponibiliza o índice a ser aplicado para os
                contratos que aniversariam entre os meses de maio do corrente
                ano a abril do ano subsequente.
              </p>
              <p>
                A ANS publicou o percentual de reajuste dos planos individuais
                ou familiares na ordem de 8,14% (período base de Maio/2020 a
                Abril/2021).
              </p>
              <p>
                A Operadora Santa Casa Saúde obteve autorização para aplicação
                do reajuste por meio do ofício GEAR n.º
                463/2020/GEFAP/GGREP/DIPRO/ANS, enviado pela ANS e m19/11/2021.
              </p>
              <p>
                Destacamos que as cobranças dos reajustes serão discriminadas
                nos boletos para facilitar a compreensão de todos.
              </p>
              <p>
                A recomposição dos reajustes poderá acumular os valores
                relativos ao reajuste por variações de custos e por variação por
                faixa etária. Há casos em que poderá coincidir a cobrança de
                percentual relativo à recomposição destas variações.
              </p>
              <p>
                Confira abaixo os exemplos de como poderá ser aplicado o
                reajuste com a recomposição dos valores retroativos nas
                simulações de cobranças a partir de janeiro de 2021:
              </p>
              <div id="tabela">
                <img
                  src={require('../../assets/img/tabela-reajuste1.png')}
                  alt="Detalhes reajuste"
                />
              </div>
              <p>
                Reajuste de plano de saúde individual/familiar regulamentado com
                aniversário em maio de 2020 (8 meses de suspensão) e sem
                previsão de reajuste por faixa etária no ano. Valor da
                mensalidade: R$ 100,00 Reajuste anual autorizado: 8,14%
              </p>
              <div id="tabela">
                <img
                  src={require('../../assets/img/tabela-reajuste2.png')}
                  alt="Detalhes reajuste"
                />
              </div>
              <p>
                Reajuste de plano de saúde individual/familiar regulamentado com
                aniversário em maio de 2020 (8 meses de suspensão) e mudança de
                faixa etária em janeiro de 2020 (4 meses de suspensão).
              </p>
              <p> Valor da mensalidade: R$ 100,00</p>
              <p> Reajuste anual autorizado: 8,14%</p>
              <p> Reajuste faixa etária considerado: 20%</p>
            </div>
            <div>
              <h4>Contratos Pessoa Jurídica até 29 vidas</h4>
              <div>
                <p>
                  A Operadora Santa Casa Saúde, devidamente registrada na
                  Agência Nacional de Saúde Suplementar ANS sob Nº 419338, em
                  atendimento e conformidade com o artigo 8.º da Resolução
                  Normativa nº 309, de 24 de outubro de 2012, da ANS – Agência
                  Nacional de Saúde Suplementar, divulga o percentual calculado
                  com base no agrupamento de contratos para o período de
                  aplicação conforme abaixo:
                </p>
                <div>
                  <p id="publicacao">Publicação: 30/04/2021</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong> Maio de 2021 a Abril de 2022.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:
                      <strong> 20,24%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/contratos-agrupados-2021.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="publicacao">Publicação: 30/04/2020</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong> Maio de 2020 a Abril de 2021.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:
                      <strong> 26,6%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/lista-de-contratos-ate-29-vidas-2020-v2.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="publicacao">Publicação: 02/05/2019</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong>Maio de 2019 a Abril de 2020.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:
                      <strong> 8,28%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/Contratos-Pessoa-Juridica-Agrupamento-Portal-OPS-2019.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="publicacao">Publicação: 01/05/2018</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong>Maio de 2018 a Abril de 2019.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:{' '}
                      <strong>13,55%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/Contratos-Pessoa-Juridica-Agrupamento-Portal-OPS-2017.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="publicacao">Publicação: 01/05/2017</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong>Maio de 2017 a Abril de 2018.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:{' '}
                      <strong>13,55%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/Contratos-Pessoa-Juridica-Agrupamento-Portal-OPS-2017.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="publicacao">Publicação: 01/05/2016</p>
                  <ul>
                    <li>
                      Período de Aplicação:{' '}
                      <strong>Maio de 2016 a Abril de 2017.</strong>
                    </li>
                    <li>
                      Percentual para o Agrupamento de Contratos:{' '}
                      <strong>15,48%.</strong>
                    </li>
                    <li>
                      Contratos Agrupados:{' '}
                      <a
                        href={require('../../assets/pdf/Contratos-Pessoa-Jurídica-Agrupamento-Portal-OPS.pdf')}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Clique Aqui
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button type="button" onClick={() => setModalReajuste(false)}>
              Fechar
            </button>
          </div>
        </Modal>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>

        <Modal
          visible={modalPlanoSaude}
          onCancel={() => setModalPlanoSaude(false)}
          footer={false}
          width="900px"
        >
          <div className="modal">
            <h3>Recomposição do Reajuste de Mensalidade e Faixa Etária</h3>
            <div>
              <p>
                <strong>Data: 30/12/2020</strong>
              </p>
              <p>
                Prezado Cliente, de acordo com a determinação da Agência
                Nacional de Saúde Suplementar (ANS), informamos Recomposição do
                Reajuste de Mensalidade e Faixa Etária.
              </p>
              <p>
                No último dia 19 de novembro a ANS (Agência Nacional de Saúde
                Suplementar), publicou as orientações sobre a Recomposição do
                Reajuste de Mensalidade e Faixa Etária, sendo que:
              </p>
              <br />
              <br />
              <h4>Pessoa Jurídica:</h4>
              <p>
                Os valores referentes à suspensão dos reajustes (mensalidades e
                faixa etária) serão cobrados a partir de Janeiro/2021, divididos
                em 12 (doze) parcelas iguais e sucessivas, sem juros.
              </p>
              <p>
                Destacamos que as cobranças dos reajustes serão discriminadas
                nos boletos para facilitar a compreensão de todos.
              </p>
              <br />
              <br />
              <h4>Pessoa Física:</h4>
              <p>
                Os valores referentes à suspensão dos reajustes (mensalidades e
                faixa etária) serão cobrados a partir de Janeiro/2021, divididos
                em 12 (doze) parcelas iguais e sucessivas, sem juros.
              </p>
              <p>
                Serão recompostos os reajustes de mensalidades dos contratos que
                tiveram seu aniversário entre Maio/2020 a Dezembro/2020.
              </p>
              <p>
                A ANS publicou o percentual de reajuste dos planos individuais
                ou familiares na ordem de 8,14% (período base de Maio/2020 a
                Abril/2021).
              </p>
              <p>
                A Operadora Santa Casa Saúde obteve autorização para aplicação
                do reajuste por meio do ofício GEAR n.º
                463/2020/GEFAP/GGREP/DIPRO/ANS, enviado pela ANS.
              </p>

              <p>
                Destacamos que as cobranças dos reajustes serão discriminadas
                nos boletos para facilitar a compreensão de todos.
              </p>
              <p>
                A recomposição dos reajustes poderá acumular os valores
                relativos ao reajuste por variações de custos e por variação por
                faixa etária. Há casos em que poderá coincidir a cobrança de
                percentual relativo à recomposição destas variações.
              </p>
              <p>
                Confira na próxima página os exemplos de como poderá ser
                aplicado o reajuste com a recomposição dos valores retroativos
                nas simulações de cobranças a partir de janeiro de 2021:
              </p>
              <img
                src={require('../../assets/img/plano-saude-01.png')}
                alt="Exemplo 01"
              />
              <p>
                Reajuste de plano de saúde individual/familiar regulamentado com
                aniversário em maio de 2020 (8 meses de suspensão) e sem
                previsão de reajuste por faixa etária no ano. Valor da
                mensalidade: R$ 100,00 Reajuste anual autorizado: 8,14%
              </p>
              <img
                src={require('../../assets/img/plano-saude-02.png')}
                alt="Exemplo 02"
              />
              <p>
                Reajuste de plano de saúde individual/familiar regulamentado com
                aniversário em maio de 2020 (8 meses de suspensão) e mudança de
                faixa etária em setembro de 2020 (4 meses de suspensão).
                <br />
                Valor da mensalidade: R$ 100,00
                <br />
                Reajuste anual autorizado: 8,14%
                <br />
                Reajuste faixa etária considerado: 20%
              </p>
              <img
                src={require('../../assets/img/plano-saude-03.png')}
                alt="Exemplo 03"
              />
              <p>
                Reajuste de plano de saúde individual/familiar regulamentado com
                aniversário em maio de 2020 (8 meses de suspensão) e mudança de
                faixa etária em janeiro de 2020 (4 meses de suspensão).
                <br />
                Valor da mensalidade: R$ 100,00
                <br />
                Reajuste anual autorizado: 8,14%
                <br />
                Reajuste faixa etária considerado: 20%
              </p>
            </div>
            <button type="button" onClick={() => setModalPlanoSaude(false)}>
              Fechar
            </button>
          </div>
        </Modal>
        <Modal
          visible={modalClientesParana}
          onCancel={() => {
            setModalClientesParana(false);
            setModalPlanoSaude(true);
          }}
          footer={false}
          width="900px"
        >
          <div className="modal">
            <img
              src={require('../../assets/img/Beneficiarios.jpg')}
              alt="PDF"
            />

            <button
              type="button"
              onClick={() => {
                setModalClientesParana(false);
                setModalPlanoSaude(true);
              }}
            >
              Fechar
            </button>
          </div>
        </Modal>
        <Footer />
      </Container>
    </>
  );
}
