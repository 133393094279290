import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// styles
import { Container, Content, Loader } from './style';

// api
import api from '../../services/api';

export default function Diagnosticos() {
  const [dados, setDados] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDados() {
      await api.get(`/pages?slug=diagnosticos`).then((response) => {
        setDados(response.data[0]);
        setLoading(false);
      });
    }
    loadDados();
  }, []);

  return (
    <>
      <Helmet>
        <title>Centro Avançado de Diagnósticos</title>
        <meta
          name="description"
          content="O Centro Avançado de Diagnósticos da Santa Casa oferece o que existe de mais moderno em termos de tecnologia."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content="https://santacasapg.com/img/site/banner-centro-avancado-de-diagnosaticos-facebook.png"
        />
      </Helmet>
      <Container>
        <Header />
        <Content className={loading ? 'nodisplay' : ''}>
          <h4>
            <img
              id="detail"
              src={require('../../assets/logo-detail.svg')}
              alt="Detalhe"
            />
            Centro Avançado de Diagnósticos
          </h4>
          <div id="content">
            <div id="imagens">
              <div>
                <img
                  src={require('../../assets/img/diagnosticos.jpg')}
                  alt="Centro Avançado de Diagnósticos"
                />
              </div>
              <img
                src={require('../../assets/img/esp-detail.svg')}
                alt="Detalhe"
                id="detail"
              />
              <div id="img2">
                <img
                  src={require('../../assets/img/diagnosticos2.jpg')}
                  alt="Centro Avançado de Diagnósticos"
                />
              </div>
            </div>
            <div id="text">
              {ReactHtmlParser(!!dados && dados.content.rendered)}
            </div>
          </div>
        </Content>
        <Loader className={loading ? '' : 'nodisplay'}>
          <div id="preloader_1">
            <span />
            <span />
            <span />
          </div>
        </Loader>
        <Footer />
      </Container>
    </>
  );
}
