import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 300;
    color: #282b81;
    text-align: center;
    margin: 80px auto 65px auto;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 36px;
    }

    span {
      font-weight: bold;
    }
  }

  #institucionalvideo {
    background-color: #f2f2f2;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 760px) {
      padding: 0 20px;
    }

    #videodiv {
      position: relative;

      @media (max-width: 760px) {
        width: 100%;
      }

      iframe {
        @media (max-width: 760px) {
          width: 100% !important;
        }

        @media (max-width: 450px) {
          height: 230px !important;
        }
      }

      img {
        position: absolute;
      }

      #detail1 {
        left: -166.91px;
        bottom: -100px;
      }

      #detail2 {
        bottom: -120px;
      }

      #detail3 {
        top: -68px;
      }
    }
  }

  #texto {
    position: relative;
    top: 165px;

    @media (max-width: 750px) {
      top: 70px;
    }

    h4 {
      margin-bottom: 35px;
      margin-top: 0;
    }

    p {
      font-size: 22px;
      line-height: 32px;
      color: #282b81;
      padding: 0 20px;
      max-width: 1050px;
      margin: 20px auto;
      text-align: center;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  #bottom-img {
    position: relative;
    z-index: -1;

    #santacasaimg {
      width: 100%;
    }

    #detail {
      position: absolute;
      z-index: 3;
      left: 0;
      bottom: -20%;
      left: 10%;
      width: 13%;
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
