import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { Container, Banner, Serviços, Frase } from './styles';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// api
import api from '../../services/api';

export default function Home() {
  const [banners, setBanners] = useState();
  const bannerSlider = useRef(null);

  const bannerSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };

  useEffect(() => {
    async function loadBanners() {
      await api.get(`/pages?slug=home`).then((response) => {
        setBanners(response.data[0]);
      });
    }
    loadBanners();
  }, []);

  return (
    <>
      <Helmet>
        <title>Santa Casa de Misericórdia de Ponta Grossa</title>
        <meta
          name="description"
          content="Proporcionamos ações em saúde de forma humanizada e autossustentável, com excelência e conhecimento atual, para o bem estar da comunidade."
        />
        <meta
          name="keywords"
          content="Santa Casa de Misericórdia de Ponta Grossa,Plano de Saúde,Centro de Diagnóstico,Centro de Especialidades,Laboratório,Banco de Sangue,Centro Cirúrgico,Maternidade,Quimioterapia,UTI Adulto,UTI Neonatal"
        />
        <meta
          property="og:image"
          content={require('../../assets/img/fachada-santacasa.jpg')}
        />
      </Helmet>
      <Container>
        <Header />
        <Banner>
          <Carousel ref={bannerSlider} {...bannerSettings}>
            {!!banners &&
              banners.acf.banners.map((banner) => (
                <div id="slider" key={Math.random()}>
                  <div id="bg-img">
                    <img
                      src={banner.imagem.sizes.large}
                      alt={banner.imagem.alt}
                    />
                  </div>

                  {ReactHtmlParser(banner.texto)}
                </div>
              ))}
          </Carousel>
        </Banner>
        <Serviços>
          <div id="text">
            <h3>
              Conheça tudo que a <br />
              <span>Santa Casa de Ponta Grossa </span> <br />
              tem para oferecer a você.
            </h3>
            <img
              id="pessoas"
              src={require('../../assets/img/pessoas.svg')}
              alt="Pessoas"
            />
            <img
              src={require('../../assets/img/servico-detail.svg')}
              alt="Detalhe"
              id="detalhe"
            />
          </div>
          <div id="serviços">
            <a href="/laboratorio">
              <div id="servico-img">
                <img
                  src={require('../../assets/img/laboratorio.jpg')}
                  alt="Laboratório"
                />
              </div>
              <p>LABORATÓRIO</p>
              <div id="hover-detail">
                <img src={require('../../assets/logo-semtxt.svg')} alt="Logo" />
              </div>
            </a>
            <a href="/plano-de-saude">
              <div id="servico-img">
                <img
                  src={require('../../assets/img/plano.jpg')}
                  alt="Plano de saúde"
                />
              </div>
              <p>PLANO DE SAÚDE</p>
              <div id="hover-detail">
                <img src={require('../../assets/logo-semtxt.svg')} alt="Logo" />
              </div>
            </a>
            <a href="/centro-de-especialidades">
              <div id="servico-img">
                <img
                  src={require('../../assets/img/especialidades.jpg')}
                  alt="Centro de especialidades"
                />
              </div>
              <p>
                CENTRO DE <br /> ESPECIALIDADES
              </p>
              <div id="hover-detail">
                <img src={require('../../assets/logo-semtxt.svg')} alt="Logo" />
              </div>
            </a>
            <a href="/centro-avancado-de-diagnosticos">
              <div id="servico-img">
                <img
                  src={require('../../assets/img/diagnosticos.jpg')}
                  alt="Centro avançado de diagnósticos"
                />
              </div>
              <p>
                CENTRO AVANÇADO <br /> DE DIAGNÓSTICOS
              </p>
              <div id="hover-detail">
                <img src={require('../../assets/logo-semtxt.svg')} alt="Logo" />
              </div>
            </a>
          </div>
        </Serviços>
        <Frase>
          <div id="texto">
            <h4>
              O maior erro que um homem pode cometer <br /> é{' '}
              <span>sacrificar a sua saúde a qualquer outra vantagem.”</span>
            </h4>
            <p>Arthur Schopenhauer</p>
          </div>
          <div id="detalhes">
            <img
              id="colorido"
              src={require('../../assets/logo-detail.svg')}
              alt="Detalhe"
            />
            <img
              id="amarelo"
              src={require('../../assets/img/frase-detalhe.svg')}
              alt="Detalhe"
            />
          </div>
        </Frase>
        <Footer />
      </Container>
    </>
  );
}
