import styled from 'styled-components';

import yellow_detail from '../../assets/img/yellow-detail.svg';
import blue_detail from '../../assets/img/blue-detail.svg';

export const Container = styled.div`
  height: 100%;
`;

export const Links = styled.div`
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  > div {
    background-color: #0094bf;
    padding: 110px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: grid;
      grid-template-columns: repeat(3, 180px);
      grid-column-gap: 100px;
      grid-row-gap: 65px;
      position: relative;

      @media (max-width: 780px) {
        grid-template-columns: repeat(2, 180px);
        grid-column-gap: 65px;
      }

      @media (max-width: 750px) {
        grid-template-columns: repeat(2, 160px);
      }

      @media (max-width: 415px) {
        grid-gap: 25px;
      }

      @media (max-width: 375px) {
        grid-template-columns: repeat(2, 145px);
      }

      #detail {
        position: absolute;
        top: -110px;
        width: 27%;
        right: -35%;
      }

      a,
      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #f3e82e;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
          margin-bottom: 20px;
        }

        p {
          font-size: 26px;
          line-height: 31px;
          font-weight: bold;
          text-align: center;

          @media (max-width: 750px) {
            font-size: 22px;
            line-height: 27px;
          }

          @media (max-width: 375px) {
            font-size: 20px;
            line-height: 25px;
          }
        }

        :nth-child(odd) {
          color: #ffffff;
        }
      }
    }
  }
`;

export const Planos = styled.div`
  padding: 85px 20px 60px 20px;
  background-color: #f2f2f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 750px) {
    padding: 50px 20px 20px 20px;
  }

  #title {
    margin-bottom: 30px;
    text-align: center;

    h4,
    p {
      font-size: 30px;
      line-height: 36px;
      color: #282b81;
      font-weight: bold;
      margin-bottom: 15px;

      @media (max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    p {
      font-size: 20px;
      margin: 0;

      @media (max-width: 750px) {
        font-size: 18px;
      }
    }
  }

  a {
    margin-bottom: 70px;
    background-color: #0094bf;
    border-radius: 12px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    line-height: 17px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all ease 0.5s;

    :hover {
      background-color: #f3e82e;
      color: #0094bf;
    }

    @media (max-width: 750px) {
      margin-bottom: 40px;
    }
  }

  #texto {
    max-width: 790px;
    position: relative;

    img {
      position: absolute;
      top: -309px;
      left: -205px;
      width: 38%;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: #282b81;
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;

export const Conheca = styled.div`
  #normativa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 30px 20px;
    background-color: #f3e82e;

    h4 {
      font-size: 30px;
      line-height: 36px;
      color: #282b81;
      font-weight: bold;
      margin-bottom: 15px;
      max-width: 490px;
      text-align: center;

      @media (max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    a {
      margin-top: 30px;
      background-color: #0094bf;
      border-radius: 12px;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      line-height: 17px;
      padding: 10px 80px;
      text-decoration: none;
      transition: all ease 0.5s;

      :hover {
        background-color: #282b81;
      }

      @media (max-width: 750px) {
        margin-bottom: 40px;
      }
    }
  }

  #nossosplanos {
    padding: 80px 20px 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h4 {
      font-size: 30px;
      line-height: 36px;
      color: #282b81;
      font-weight: bold;
      margin-bottom: 115px;
      max-width: 490px;
      text-align: center;
      position: relative;

      @media (max-width: 750px) {
        font-size: 24px;
        line-height: 30px;
      }

      #detailnossosplanos {
        position: absolute;
        top: -80px;
        right: -300px;
      }
    }

    #planos {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: -130px;
      padding-top: 130px;
      width: 100%;
      position: relative;
      max-width: 800px;

      #row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: fit-content;
        margin: 15px 0;

        @media (max-width: 850px) {
          flex-direction: column-reverse;
          margin-bottom: 50px;
          transition: all ease 1s;
        }

        #card {
          width: 305px;
          height: 305px;
          background-color: #f3e82e;
          border-radius: 12px;
          background-image: url(${yellow_detail});
          background-repeat: no-repeat;
          background-size: 25%;
          background-position: left 95%;
          position: relative;
          justify-self: flex-end;
          z-index: 1;
          margin-left: 40px;

          @media (max-width: 850px) {
            margin-left: 0;
          }

          h4 {
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            color: #282b81;
            text-align: start;
            max-width: 145px;
            margin-top: 20px;
            margin-left: 30px;
            z-index: 1;
          }

          img {
            position: absolute;
            bottom: 0;
            left: 110px;
          }
        }

        #text {
          max-width: 455px;
          position: relative;
          transition: all ease 1s;

          @media (max-width: 850px) {
            margin-top: 40px;
            max-width: 80%;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            color: #282b81;
            text-align: end;

            @media (max-width: 850px) {
              text-align: center;
            }
          }
        }
      }

      #rowpar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        margin: 15px 0;

        @media (max-width: 850px) {
          flex-direction: column;
          margin-bottom: 50px;
          transition: all ease 0.5s;
        }

        #card {
          width: 305px;
          height: 305px;
          background-color: #f3e82e;
          border-radius: 12px;
          background-color: #0094bd;
          background-image: url(${blue_detail});
          background-repeat: no-repeat;
          background-size: 40%;
          background-position: right 95%;
          position: relative;
          z-index: 1;

          h4 {
            text-align: end;
            max-width: 165px;
            margin-left: 110px;
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            color: #282b81;
            margin-top: 20px;
            z-index: 1;
          }

          img {
            position: absolute;
            right: 120px;
            bottom: 0;
          }
        }

        #textpar {
          max-width: 455px;
          position: relative;
          transition: all ease 1s;

          @media (max-width: 850px) {
            margin-top: 40px;
            max-width: 80%;
          }

          @media (max-width: 550px) {
            max-width: 100%;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            color: #282b81;
            text-align: start;

            @media (max-width: 850px) {
              text-align: center;
            }
          }
        }
      }
    }
  }
`;

export const Anexos = styled.div`
  padding: 45px 20px 150px 20px;
  background-color: #f2f2f2;

  h4 {
    font-size: 30px;
    line-height: 36px;
    color: #282b81;
    font-weight: bold;
    margin-bottom: 40px;
    max-width: 575px;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 30px;
      max-width: 460px;
    }
  }

  p {
    font-size: 16px;
    line-height: 30px;
    color: #282b81;
    text-align: center;
    margin-bottom: 40px;

    span {
      font-weight: bold;
    }
  }

  a,
  button {
    background-color: #0094bf;
    border-radius: 12px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    line-height: 17px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all ease 0.5s;
    border: none;

    :hover {
      background-color: #282b81;
    }
  }

  button {
    margin-top: 25px;
    cursor: pointer;
  }

  #formulario {
    display: flex;
    margin-bottom: 40px;
  }

  #idss {
    a {
      margin: 5px 10px;
      padding: 10px 40px;
    }
  }

  .idss {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  #anos {
    a {
      margin: 0 10px;
      padding: 10px 40px;
    }

    #anopassado {
      background-color: #282b81;

      :hover {
        background-color: #0094bf;
      }
    }
  }

  #servico-de-atendimento-ao-beneficiario {
    padding-top: 150px;
    margin-top: -150px;
  }

  > div {
    max-width: 900px;
    padding: 40px 0;
    border-bottom: 1px solid #282b81;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  #links {
    border: none;
    position: relative;

    img {
      position: absolute;
      bottom: -325px;
      left: -200px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      align-self: flex-start;
    }

    #inputs {
      display: flex;
      width: 100%;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      > div {
        display: flex;
        flex-direction: column;
        width: 32%;
        margin: 10px 5px;

        @media (max-width: 600px) {
          width: 100%;
          margin: 5px 0px;
        }
      }
    }

    input {
      width: 100%;
      height: 35px;

      ::-webkit-input-placeholder {
        color: #0094bf;
      }
    }

    textarea {
      width: 98%;
      padding-top: 15px;
      resize: none;
      height: 155px;

      @media (max-width: 600px) {
        width: 100%;
        margin-top: 5px;
      }

      ::-webkit-input-placeholder {
        color: #0094bf;
      }
    }

    input,
    textarea {
      background-color: transparent;
      border: 1px solid #0094bf;
      border-radius: 12px;
      padding-left: 15px;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      color: #282b81;
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;

  @media (max-width: 1380px) {
    padding-top: 155px;
  }

  @media (max-width: 905px) {
    padding-top: 115px;
  }

  @media (max-width: 450px) {
    padding-top: 85px;
  }

  #preloader_1 {
    position: absolute;
    width: 80px;
    height: 40px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 100%;
    background: #0194c0;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 25px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 50px;
    animation-delay: 0.4s;
  }

  @keyframes preloader_1 {
    0% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    25% {
      height: 25px;
      width: 25px;
      transform: translateY(-10px);
    }
    50% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
    100% {
      height: 20px;
      width: 20px;
      transform: translateY(0px);
    }
  }
`;
